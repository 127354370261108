export type CostType = {
  CostType: string;
  Number: string;
  Date: string;
  BookingDate: string;
  CostValue: number;
  OwnerChargeSplit: number;
  OwnerCostValue: number;
  RoomName: string;
  Comments: string;
  CostTransID: undefined | string | number;
};

export const formInitialValues = {
  CostType: '',
  Number: '',
  Date: '',
  BookingDate: '',
  CostValue: 0,
  OwnerChargeSplit: 0,
  OwnerCostValue: 0,
  RoomName: '',
  Comments: '',
  CostTransID: undefined,
};
