import React, { useEffect, useState } from "react";
import { AutoComplete, Form, Input, Select } from "antd";
import axios from "axios";
import { CostType } from "../../@types/cost";
import { useParams } from "react-router-dom";

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: "number" | "text";
  record: CostType;
  index: number;
  test: Function;
  children: React.ReactNode;
}

type TypeListNameRoom = {
  value: string;
};

type TypesListType = {
  label: string;
  key: number;
};

const EditableCellAll: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  test,
  ...restProps
}) => {
  const [listNameRoom, setListNameRoom] = useState<TypeListNameRoom[]>([]);
  const { ownerId } = useParams();
  const [optionsAutoComplete, setOptionsAutoComplete] = useState<
    TypeListNameRoom[]
  >([]);
  const [typesList, setTypesList] = useState<TypesListType[]>([]);
  const menu = async () => {
    try {
      const res: string[] = await axios
        .get(`/costs/cost-types`)
        .then((res) => res.data);
      const newMenu = res.map((item, i) => {
        return {
          label: item,
          key: i,
        };
      });
      setTypesList(newMenu);
    } catch (err) {
      console.log(err);
    }
  };

  const onSearchAutoComplete = (searchText: string) => {
    if (!searchText) {
      const rooms = listNameRoom.map(({ value }) => {
        return { value };
      });
      return setOptionsAutoComplete(rooms);
    }

    const filteredRooms = listNameRoom.filter(
      ({ value }) => value.indexOf(searchText) >= 0
    );
    setOptionsAutoComplete(filteredRooms);
  };

  const fetchListNameRoom = async () => {
    try {
      const res = await axios
        .get(`/apartments/all-rooms`, {
          params: ownerId ? { ownerId } : {},
        })
        .then((res) => res.data);
      const listNameRoom: TypeListNameRoom[] = res.map((room: string) => ({
        value: room,
      }));
      setListNameRoom(listNameRoom);
      const rooms = listNameRoom.map(({ value }) => {
        return { value };
      });
      return setOptionsAutoComplete(rooms);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (dataIndex === "RoomName") {
      fetchListNameRoom();
    }
    if (dataIndex === "CostType") {
      menu();
    }
  }, []);

  const inputNode =
    dataIndex === "RoomName" ? (
      <AutoComplete
        options={optionsAutoComplete}
        style={{
          width: "100%",
        }}
        onSearch={onSearchAutoComplete}
        placeholder="input here"
        children={<Input type="search" />}
      />
    ) : dataIndex === "CostType" ? (
      <Select
        id="CostType"
        style={{
          width: "100%",
        }}
      >
        {typesList.map((item) => (
          <Select.Option id={"CostType"} key={item.label}>
            {item.label}
          </Select.Option>
        ))}
      </Select>
    ) : dataIndex === "OwnerCostValue" ? (
      <Input
        disabled
        style={{
          background: "white",
          color: "black",
          border: 0,
        }}
      />
    ) : dataIndex === "Date" || dataIndex === "BookingDate" ? (
      <Input type="date" />
    ) : dataIndex === "Comments" || dataIndex === "Number" ? (
      <Input />
    ) : (
      <Input type="number" onChange={() => test()} />
    );

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please Input ${dataIndex}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

export default EditableCellAll;
