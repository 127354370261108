import React from "react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, DatePicker, Form, Popconfirm, Table, Typography } from "antd";
import { RootState } from "../../store";

import moment, { Moment } from "moment";
import axios from "axios";
import { useTranslation } from "react-i18next";
import CostExportExcel from "./CostExportExcel";
import { CostType, formInitialValues } from "../../@types/cost";

import EditableRow from "./EditableRow";
import EditableCell from "./EditableCell";
import EditableCellAll from "./EditableCellAll";
import { useParams } from "react-router-dom";

type EditableTableProps = Parameters<typeof Table>[0];
type ColumnTypes = Exclude<EditableTableProps["columns"], undefined>;

const Cost: React.FC = () => {
  const [listCosts, setListCosts] = useState<CostType[]>([]);
  const [dateFrom, setDateFrom] = useState<Moment | null>(
    moment().startOf("year")
  );
  const [dateTo, setDateTo] = useState<Moment | null>(moment().endOf("year"));
  const params = useParams();

  const user = useSelector((state: RootState) => state.auth.user);
  const verifyUser = user?.Role === "admin" || user?.Role === "super-admin";
  const [t] = useTranslation("common");

  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState("");
  const isEditing = (record: any) => record.CostTransID === editingKey;

  const test = async () => {
    const values = await form.getFieldsValue();
    const { OwnerChargeSplit, CostValue } = values;
    const newCostValue = Number(OwnerChargeSplit) * Number(CostValue);
    form.setFieldsValue({ ...values, OwnerCostValue: newCostValue.toFixed(2) });
  };

  const components = {
    body: {
      row: editingKey ? undefined : EditableRow,
      cell: editingKey ? EditableCellAll : EditableCell,
    },
  };

  const edit = (record: any) => {
    form.setFieldsValue({ ...formInitialValues, ...record });
    setEditingKey(record.CostTransID);
  };

  const handleAdd = () => {
    const arrayId =
      listCosts.map(({ CostTransID }) => Number(CostTransID)) || [];
    const newId = Math.max(...arrayId) + 1;
    const newCost = { ...formInitialValues, CostTransID: newId };
    setListCosts([...listCosts, newCost]);
    edit(newCost);
  };

  const fetchListCost = async () => {
    try {
      const res = await axios
        .get("/costs/list", {
          params: {
            from: dateFrom ? dateFrom.format("YYYY-MM-DD") : "",
            to: dateTo ? dateTo.format("YYYY-MM-DD") : "",
            ...(params.ownerId ? { ownerId: params.ownerId } : {}),
          },
        })
        .then((res) => res.data);

      setListCosts(res);
    } catch (err) {
      console.log(err);
    }
  };

  const deleteCost = async (costID: string | number) => {
    try {
      await axios["delete"](`/costs/${costID}`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      fetchListCost();
    } catch (err) {
      console.log(err);
    }
  };

  const save = async (key: string | number) => {
    try {
      const row = await form.validateFields();
      const verifyDate = {
        ...row,
        OwnerCostValue: Number(row.CostValue) * Number(row.OwnerChargeSplit),
      };
      await axios["post"](
        `/costs/`,
        {
          ...verifyDate,
          Date: moment(verifyDate.Date).format("YYYY-MM-DD"),
          BookingDate: moment(verifyDate.BookingDate).format("YYYY-MM-DD"),
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      ).then(() => {
        fetchListCost();
        setEditingKey("");
      });
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const cancel = () => {
    fetchListCost();
    setEditingKey("");
  };

  const columns: (ColumnTypes[number] & {
    editable?: boolean;
    dataIndex: string;
  })[] = [
    {
      title: t("cost.table.CostTransID"),
      dataIndex: "CostTransID",
      defaultSortOrder: "ascend",
      width: 50,
      sorter: (a: any, b: any) =>
        (a.CostTransID as unknown as string) >
        (b.CostTransID as unknown as string)
          ? 1
          : -1,
    },
    {
      title: t("cost.table.Number"),
      dataIndex: "Number",
      width: 120,
      editable: verifyUser,
      sorter: (a: any, b: any) =>
        (a.Number as string) > (b.Number as string) ? 1 : -1,
    },
    {
      title: t("cost.table.CostType"),
      dataIndex: "CostType",
      editable: verifyUser,
      width: 150,
      sorter: (a: any, b: any) =>
        (a.CostType as string) > (b.CostType as string) ? 1 : -1,
      render: (CostType: string) => {
        return <span className="whitespace-nowrap">{CostType}</span>;
      },
    },
    {
      title: <div>{t("cost.table.Date")}</div>,
      dataIndex: "Date",
      editable: verifyUser,
      width: 200,
      sorter: (a: any, b: any) =>
        (a.Date as string) > (b.Date as string) ? 1 : -1,
      render: (Date: string) => {
        return (
          <span className="whitespace-nowrap">
            {Date ? moment(Date).format("YYYY-MM-DD") : ""}
          </span>
        );
      },
    },
    {
      title: <div>{t("cost.table.BookingDate")}</div>,
      dataIndex: "BookingDate",
      editable: verifyUser,
      width: 200,
      sorter: (a: any, b: any) =>
        (a.BookingDate as string) > (b.BookingDate as string) ? 1 : -1,
      render: (BookingDate: string) => {
        return (
          <span className="whitespace-nowrap">
            {BookingDate ? moment(BookingDate).format("YYYY-MM-DD") : ""}
          </span>
        );
      },
    },
    {
      title: t("cost.table.CostValue"),
      dataIndex: "CostValue",
      editable: verifyUser,
      width: 120,
      sorter: (a: any, b: any) =>
        (a.CostValue as number) > (b.CostValue as number) ? 1 : -1,
    },
    {
      title: <div>{t("cost.table.OwnerChargeSplit")}</div>,
      dataIndex: "OwnerChargeSplit",
      editable: verifyUser,
      width: 120,
      sorter: (a: any, b: any) =>
        (a.CostValue as number) > (b.CostValue as number) ? 1 : -1,
    },
    {
      title: t("cost.table.OwnerCostValue"),
      dataIndex: "OwnerCostValue",
      editable: true,
      width: 90,
      sorter: (a: any, b: any) =>
        (a.OwnerCostValue as number) > (b.OwnerCostValue as number) ? 1 : -1,
      render: (OwnerCostValue: string) => {
        return <span className="whitespace-nowrap">{OwnerCostValue}</span>;
      },
    },
    {
      title: t("cost.table.RoomName"),
      dataIndex: "RoomName",
      width: 150,
      editable: verifyUser,
      sorter: (a: any, b: any) =>
        (a.RoomName as string) > (b.RoomName as string) ? 1 : -1,
      render: (RoomName: string) => {
        return <span className="whitespace-nowrap">{RoomName}</span>;
      },
    },
    {
      title: t("cost.table.Comments"),
      dataIndex: "Comments",
      width: 200,
      editable: verifyUser,
      sorter: (a: any, b: any) =>
        (a.Comments as string) > (b.Comments as string) ? 1 : -1,
      render: (Comments: string) => {
        return <span className="whitespace-nowrap">{Comments}</span>;
      },
    },
    {
      title: "operation",
      dataIndex: "operation",
      render: (_, record: any) => {
        const editable = isEditing(record);

        return listCosts.length >= 1 && verifyUser && editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.CostTransID)}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </Typography.Link>
            <Popconfirm
              title="Sure to cancel?"
              onConfirm={cancel}
              okType="danger"
            >
              <button>Cancel</button>
            </Popconfirm>
          </span>
        ) : listCosts.length >= 1 && verifyUser ? (
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => deleteCost(record.CostTransID)}
            okType="danger"
          >
            <Button key="delete" className="btn-danger">
              {t("DELETE")}
            </Button>
          </Popconfirm>
        ) : null;
      },
    },
  ];

  useEffect(() => {
    fetchListCost();
  }, []);

  const onDateChange = (dates: any) => {
    setDateFrom(dates ? dates[0] : null);
    setDateTo(dates ? dates[1] : null);
  };

  const disabledDate = (current: any) => {
    return current && current < moment("2021-12-31").endOf("day");
  };

  const showColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record: any) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        test: () => test(),
        handleSave: () => fetchListCost(),
      }),
    };
  });

  return (
    <div className="container-xl mx-auto px-3 h-full pt-7 flex flex-col">
      <div className="mt-8 border-b mb-2 border-gray-400 lg:flex justify-between">
        <div className="flex items-center mb-2">
          <span className="font-bold mr-4">{t("transactions.Period")}:</span>

          <DatePicker.RangePicker
            ranges={{
              "This Month": [
                moment().startOf("month"),
                moment().endOf("month"),
              ],
            }}
            value={[dateFrom, dateTo]}
            onChange={onDateChange}
            disabledDate={verifyUser ? () => {} : disabledDate}
          />

          <Button
            className="btn-default h-8 ml-2"
            onClick={() => fetchListCost()}
          >
            {t("Submit")}
          </Button>
        </div>
      </div>

      <div className="max-w-full overflow-auto">
        <Form form={form} component={false}>
          <Table
            components={components}
            rowKey="CostTransID"
            columns={showColumns as ColumnTypes}
            dataSource={listCosts}
            rowClassName={"hover:bg-white hover:bg-opacity-10"}
            className="border flex-grow"
            pagination={{
              hideOnSinglePage: true,
              defaultPageSize: 100,
            }}
          />
        </Form>
      </div>
      <div
        className="flex"
        style={{ width: "100%", justifyContent: "flex-end", marginTop: "10px" }}
      >
        {verifyUser && (
          <Button
            disabled={editingKey !== ""}
            onClick={handleAdd}
            type="primary"
            className="btn-yellow hvr-float-shadow h-10 w-40 ml-3"
            style={{ marginBottom: 16 }}
          >
            Add a row
          </Button>
        )}
        <CostExportExcel rows={listCosts} dateFrom={dateFrom} dateTo={dateTo} />
      </div>
    </div>
  );
};

export default Cost;
