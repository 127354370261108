import { AutoComplete, Form, Input } from "antd";
import axios from "axios";
import moment from "moment";
import { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { SentSummariesType } from "../../@types/sentsummaries";
import { EditableContext } from "./EditableRow";

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof SentSummariesType;
  record: any;
  handleSave: () => void;
}

type TypeListNameRoom = {
  value: string;
};

const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<any>(null);
  const form = useContext(EditableContext)!;
  const [listNameRoom, setListNameRoom] = useState<TypeListNameRoom[]>([]);
  const { ownerId } = useParams();
  const [optionsAutoComplete, setOptionsAutoComplete] = useState<
    TypeListNameRoom[]
  >([]);

  const fetchListNameRoom = async () => {
    try {
      const res = await axios
        .get(`/apartments/all-rooms`, {
          params: ownerId ? { ownerId } : {},
        })
        .then((res) => res.data);
      const listNameRoom: TypeListNameRoom[] = res.map((room: string) => ({
        value: room,
      }));
      setListNameRoom(listNameRoom);
      const rooms = listNameRoom.map(({ value }) => {
        return { value };
      });
      return setOptionsAutoComplete(rooms);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (editing) {
      inputRef.current!.focus();

      if (
        dataIndex === "DateFrom" ||
        dataIndex === "DateTo" ||
        dataIndex === "SentON"
      ) {
        form.setFieldsValue({
          [dataIndex]: moment(record[dataIndex]).format("YYYY-MM-DD"),
        });
      }
      if (dataIndex === "RoomName") {
        fetchListNameRoom();
      }
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      var newBody = {
        ...record,
        ...values,
      };

      newBody = {
        ...newBody,
      };

      delete newBody.ID;
      delete newBody.ExportType;

      toggleEdit();
      await axios["put"](
        `/sentsummaries/${record.ID || ""}`,
        { ...newBody },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      ).then(() => {
        handleSave();
      });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  let childNode: any = children;

  const onSearchAutoComplete = (searchText: string) => {
    if (!searchText) {
      const rooms = listNameRoom.map(({ value }) => {
        return { value };
      });
      return setOptionsAutoComplete(rooms);
    }

    const filteredRooms = listNameRoom.filter(
      ({ value }) => value.indexOf(searchText) >= 0
    );
    setOptionsAutoComplete(filteredRooms);
  };

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        {dataIndex === "RoomName" ? (
          <AutoComplete
            style={{
              width: "100%",
            }}
            placeholder="input here"
            onSearch={onSearchAutoComplete}
            options={optionsAutoComplete}
            children={
              <Input
                type="search"
                ref={inputRef}
                onPressEnter={save}
                onBlur={save}
              />
            }
          />
        ) : dataIndex === "DateFrom" ||
          dataIndex === "DateTo" ||
          dataIndex === "SentON" ? (
          <Input type="date" ref={inputRef} onPressEnter={save} onBlur={save} />
        ) : dataIndex === "CostTotal" ||
          dataIndex === "parkingTotal" ||
          dataIndex === "ApartmentTotal" ||
          dataIndex === "FinalTotal" ? (
          <Input
            type="number"
            ref={inputRef}
            onPressEnter={save}
            onBlur={save}
          />
        ) : (
          <Input ref={inputRef} onPressEnter={save} onBlur={save} />
        )}
      </Form.Item>
    ) : (
      <div
        className="cursor-pointer editable-cell-value-wrap"
        style={{
          paddingRight: 24,
          width: "100%",
          height: "25px",
          padding: "1px",
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

export default EditableCell;
