import { useState, useEffect } from "react";

import { Button, Modal, Table, message, InputNumber, AutoComplete, DatePicker } from "antd";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { ColumnsType } from "antd/es/table";
import { ApartmentCleaning } from "../../@types/apartmentcleaning";
import { useTranslation } from "react-i18next";

import axios from "axios";
import moment from "moment";

interface ApartmentCleaningProps {
  visible: boolean;
  onCancel: () => void;
  RoomName: string | null;
}

const ApartmentCleaningModal: React.FC<ApartmentCleaningProps> = (props) => {
  const emptyRow = {
    cleaningID: "",
    RoomName: props.RoomName as string,
    CleaningFee: 0,
    OwnerCleaningFee: 0,
    StartDate: "",
    EndDate: "",
  };

  const user = useSelector((state: RootState) => state.auth.user);
  const [selectedRowKeys, setSelectedRowKeys] = useState<Array<any>>([]);
  const [initialValues, setInitialValues] = useState<Array<ApartmentCleaning>>([
    emptyRow,
  ]);
  const [t] = useTranslation("common");

  const formSchema = Yup.object({
    apartmentsCleaning: Yup.array().of(
      Yup.object().shape({
        cleaningID: Yup.string(),
        RoomName: Yup.string().required("Required"),
        CleaningFee: Yup.string().required("Required"),
        OwnerCleaningFee: Yup.string().required("Required"),
        StartDate: Yup.string().required("Required"),
        EndDate: Yup.string().required("Required"),
      })
    ),
  });

  const formik = useFormik({
    initialValues: {
      apartmentsCleaning: initialValues,
    },
    enableReinitialize: true,
    validationSchema: formSchema,
    onSubmit: async (values, { setSubmitting }) => {
      const newData = [...values.apartmentsCleaning];
      
      newData.forEach((admin: any) => {
        admin.StartDate = admin?.StartDate
          ? moment(admin?.StartDate).format("YYYY-MM-DD HH:mm:ss")
          : null;
        admin.EndDate = admin?.EndDate
          ? moment(admin?.EndDate).format("YYYY-MM-DD HH:mm:ss")
          : null;
      });

      setSubmitting(true);
      try {
        const res = await axios
          .post(`/apartments-cleaning`, { apartmentsCleaning: newData })
          .then((res) => res.data);

        if (res?.success) {
          message.success(t("Saved successfully."));
          props.onCancel();
        }
      } catch (err: any) {
        console.log(err);
      }
      setSubmitting(false);
    },
  });

  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleSubmit,
    setSubmitting,
    isSubmitting,
    resetForm,
  } = formik;

  const columns: ColumnsType<ApartmentCleaning> = [
    {
      title: t("apartments.apartmentCleaning.Room"),
      dataIndex: "RoomName",
      width: 150,
    },
    {
      title: t("apartments.apartmentCleaning.Cleaning Fee"),
      dataIndex: "CleaningFee",
      width: 130,
      render: (CleaningFee: number, record: ApartmentCleaning, index: number) => {
        return (
          <InputNumber
            placeholder="Cleaning Fee"
            disabled={user?.Role === "admin" || user?.Role === "super-admin" ? false : true}
            className={`w-full h-10 ${
              errors.apartmentsCleaning &&
              touched.apartmentsCleaning &&
              touched.apartmentsCleaning[index]?.CleaningFee &&
              Boolean((errors.apartmentsCleaning[index] as any)?.CleaningFee) &&
              "border-red-500"
            }`}
            value={CleaningFee}
            onChange={(value) =>
              setFieldValue(`apartmentsCleaning[${index}]CleaningFee`, value)
            }
            step="0.01"
          />
        );
      },
    },
    {
      title: t("apartments.apartmentCleaning.Owner Cleaning Fee"),
      dataIndex: "OwnerCleaningFee",
      width: 150,
      render: (OwnerCleaningFee: number, record: ApartmentCleaning, index: number) => {
        return (
          <InputNumber
            placeholder="Owner Cleaning Fee"
            disabled={user?.Role === "admin" || user?.Role === "super-admin" ? false : true}
            className={`w-full h-10 ${
              errors.apartmentsCleaning &&
              touched.apartmentsCleaning &&
              touched.apartmentsCleaning[index]?.OwnerCleaningFee &&
              Boolean((errors.apartmentsCleaning[index] as any)?.OwnerCleaningFee) &&
              "border-red-500"
            }`}
            value={OwnerCleaningFee}
            onChange={(value) =>
              setFieldValue(`apartmentsCleaning[${index}]OwnerCleaningFee`, value)
            }
            step="0.01"
          />
        );
      },
    },
    {
      title: t("apartments.apartmentCleaning.Start date"),
      dataIndex: "StartDate",
      width: 140,
      render: (StartDate: number, record: ApartmentCleaning, index: number) => {
        return (
          <DatePicker
          placeholder={t("owners.profile.Start date")}
          className={`w-full h-10 ${
            errors.apartmentsCleaning &&
            touched.apartmentsCleaning &&
            touched.apartmentsCleaning[index]?.StartDate &&
            Boolean((errors.apartmentsCleaning[index] as any)?.StartDate) &&
            "border-red-500"
          }`}
          name="StartDate"
          value={StartDate ? moment(StartDate) : null}
          disabled={user?.Role === "admin" || user?.Role === "super-admin" ? false : true}
          onChange={(value) =>
            setFieldValue(`apartmentsCleaning[${index}]StartDate`, value ? value.format("YYYY-MM-DD") : null)
          }
        />
        );
      },
    },
    {
      title: t("apartments.apartmentCleaning.End date"),
      dataIndex: "EndDate",
      width: 140,
      render: (EndDate: number, record: ApartmentCleaning, index: number) => {
        return (
          <DatePicker
          placeholder={t("owners.profile.End date")}
          className={`w-full h-10 ${
            errors.apartmentsCleaning &&
            touched.apartmentsCleaning &&
            touched.apartmentsCleaning[index]?.EndDate &&
            Boolean((errors.apartmentsCleaning[index] as any)?.EndDate) &&
            "border-red-500"
          }`}
          name="EndDate"
          value={EndDate ? moment(EndDate) : null}
          disabled={user?.Role === "admin" || user?.Role === "super-admin" ? false : true}
          onChange={(value) =>
            setFieldValue(`apartmentsCleaning[${index}]EndDate`, value ? value.format("YYYY-MM-DD") : null)
          }
        />
        );
      },
    },
  ];

  const addEmptyRow = () => {
    setFieldValue("apartmentsCleaning", [...values.apartmentsCleaning, emptyRow]);
  };

  const confirmDelete = () => {
    Modal.confirm({
      title: (
        <div className="text-white text-center">
          {t("Do you want to delete")} ?
        </div>
      ),
      okText: t("YES"),
      icon: null,
      cancelText: t("NO"),
      width: 340,
      okButtonProps: {
        className: "btn-yellow hvr-float-shadow w-32 h-10 text-xs ml-3.5",
      },
      cancelButtonProps: {
        className: "btn-danger hvr-float-shadow w-32 h-10 text-xs",
      },
      onOk: async () => {
        const dataIds = selectedRowKeys
          .map((key) => key.split("_")[0])
          .filter((id) => Boolean(id));

        setSubmitting(true);
        try {
          if (dataIds.length > 0) {
            const res = await axios
              .delete("/apartments-cleaning", { data: { ids: dataIds } })
              .then((res) => res.data);
            if (res?.success) {
              message.success(t("Deleted successfully."));
            }
          }
        } catch (err) {
          console.log(err);
        }

        const apartmentsCleaning = values.apartmentsCleaning.filter(
          (row, index) =>
            selectedRowKeys.indexOf(`${row.cleaningID}_${index}`) === -1
        );
        setFieldValue("apartmentsCleaning", apartmentsCleaning);

        setSubmitting(false);
      },
      onCancel() {},
    });
  };

  const deleteRows = async () => {
    if (selectedRowKeys.length === 0) {
      message.warning(t("Select rows first."));
      return;
    }

    confirmDelete();
  };

  const fetchRows = async () => {
    try {
      const res = await axios
        .get("/apartments-cleaning/list", {
          params: {
            RoomName: props.RoomName,
          },
        })
        .then((res)=>{ return res.data; });

      setInitialValues(res);
    } catch (err) {
      console.log(err);
    }
  };


  useEffect(() => {
    if (props.visible) {
      fetchRows();
    } else {
      resetForm();
      setSelectedRowKeys([]);
    }
  }, [props.visible]);

  return (
    <Modal
      closable={true}
      visible={props.visible}
      footer={false}
      width={800}
      centered
      className="modal-body-p-0 close-outside"
      onCancel={() => props.onCancel()}
    >
      <form
        onSubmit={handleSubmit}
        className="flex flex-col items-center bg-white"
      >
        <Table
          rowKey={(record, index) => {
            return `${record.cleaningID}_${index}`;
          }}
          rowSelection={{
            selectedRowKeys,
            onChange: (values) => setSelectedRowKeys(values),
          }}
          columns={columns}
          dataSource={values.apartmentsCleaning}
          className="w-full table-sm"
          rowClassName="cursor-pointer hover:bg-c-blue hover:bg-opacity-10"
          pagination={false}
        />

        <div
          className={`flex mt-5 mb-3 ${user?.Role === "admin" || user?.Role === "super-admin" ? "" : "hidden"}`}
        >
          <Button
            key="delete"
            className="btn-danger hvr-float-shadow h-9 w-36 ml-3.5 text-xs"
            onClick={deleteRows}
            disabled={isSubmitting}
          >
            {t("DELETE")}
          </Button>
          <Button
            key="add"
            onClick={addEmptyRow}
            className="btn-dark hvr-float-shadow h-9 w-36 ml-3.5 px-0 text-xs"
            disabled={isSubmitting}
          >
            {t("apartments.apartmentCleaning.ADD")}
          </Button>
          <Button
            key="save"
            htmlType="submit"
            disabled={isSubmitting}
            className="btn-yellow hvr-float-shadow h-9 w-36 ml-2 text-xs"
          >
            {t("SAVE")}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default ApartmentCleaningModal;
