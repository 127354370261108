import React from "react";
import ApartmentCalendarFrom from "./ApartmentCalendarFrom";
import ParkingCalendarFrom from "./ParkingCalendarFrom";

const Calendar = () => (
  <div className="container mx-auto px-3 mt-7">
    <div className="bg-c-light rounded py-4 pl-6 mb-5 min-h-[500px] flex justify-center">
      <div>
        <ApartmentCalendarFrom />
        <ParkingCalendarFrom />
      </div>
    </div>
  </div>
);

export default Calendar;
