import { Button } from "antd";
import ExcelJS from "exceljs";
import saveAs from "file-saver";
import { useTranslation } from "react-i18next";

import { Moment } from "moment";

type SProps = {
  rows: Array<any>;
  dateFrom: Moment | null;
  dateTo: Moment | null;
};

const CostExportExcel: React.FC<SProps> = (props) => {
  const [t] = useTranslation("common");

  const excelData = props.rows.map((row) => {
    return {
      ID: row.ID,
      SentON: row.SentON,
      SentBy: row.SentBy,
      RoomName: row.RoomName,
      ParkingName: row.ParkingName,
      DateFrom: row.DateFrom,
      DateTo: row.DateTo,
      OwnerName: row.OwnerName,
      ApartmentTotal: row.ApartmentTotal,
      CostTotal: row.CostTotal,
      parkingTotal: row.parkingTotal,
      FinalTotal: row.FinalTotal,
      BusinessSegment: row.BusinessSegment,
      SourceAccount: row.SourceAccount,
      DestinationAccount: row.DestinationAccount,
    };
  });

  const exportExcel = async () => {
    try {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Parking Transactions", {
        views: [{ state: "frozen", ySplit: 1 }],
      });

      worksheet.columns = [
        {
          header: t("sentsummaries.table.ID"),
          key: "ID",
          width: 20,
        },
        {
          header: t("sentsummaries.table.SentON"),
          key: "SentON",
          width: 20,
        },
        {
          header: t("sentsummaries.table.SentBy"),
          key: "SentBy",
          width: 20,
        },
        {
          header: t("sentsummaries.table.RoomName"),
          key: "RoomName",
          width: 20,
        },
        {
          header: t("sentsummaries.table.ParkingName"),
          key: "ParkingName",
          width: 20,
        },
        {
          header: t("sentsummaries.table.DateFrom"),
          key: "DateFrom",
          width: 20,
        },
        {
          header: t("sentsummaries.table.DateTo"),
          key: "DateTo",
          width: 30,
        },
        {
          header: t("sentsummaries.table.OwnerName"),
          key: "OwnerName",
          width: 25,
        },
        {
          header: t("sentsummaries.table.ApartmentTotal"),
          key: "ApartmentTotal",
          width: 20,
        },
        {
          header: t("sentsummaries.table.CostTotal"),
          key: "CostTotal",
          width: 20,
        },
        {
          header: t("sentsummaries.table.parkingTotal"),
          key: "parkingTotal",
          width: 20,
        },
        {
          header: t("sentsummaries.table.FinalTotal"),
          key: "FinalTotal",
          width: 20,
        },
        {
          header: t("sentsummaries.table.BusinessSegment"),
          key: "BusinessSegment",
          width: 20,
        },
        {
          header: t("sentsummaries.table.SourceAccount"),
          key: "SourceAccount",
          width: 20,
        },
        {
          header: t("sentsummaries.table.DestinationAccount"),
          key: "DestinationAccount",
          width: 20,
        },
      ];

      worksheet.getColumn(1).alignment = {
        vertical: "bottom",
        horizontal: "left",
      };
      worksheet.getColumn(2).alignment = {
        vertical: "bottom",
        horizontal: "left",
      };

      worksheet.getRow(1).font = { bold: true, size: 14 };
      worksheet.getRow(1).height = 20;

      worksheet.addRows(excelData);

      worksheet.getRow(excelData.length + 2).font = { bold: true, size: 12 };

      const buffer = await workbook.xlsx.writeBuffer();
      saveAs(
        new Blob([buffer], { type: "application/octet-stream" }),
        `SentSummariesTransactions${
          props.dateFrom || props.dateTo
            ? `(${props.dateFrom?.format(
                "YYYY.MM.DD"
              )} - ${props.dateTo?.format("YYYY.MM.DD")})`
            : ""
        }.xlsx`
      );
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Button
      className="btn-default hvr-float-shadow h-10 w-40 ml-3"
      onClick={exportExcel}
    >
      {t("EXPORT XLS")}
    </Button>
  );
};

export default CostExportExcel;
