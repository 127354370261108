import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Link, useSearchParams } from "react-router-dom";
import { AppDispatch, RootState } from "../../../../store";
import {
  Button,
  DatePicker,
  Select,
  Table,
  Modal,
  InputNumber,
  AutoComplete,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { ColumnsType } from "antd/es/table";
import { loadApartments } from "../../../../store/apartmentsSlice";
import { loadParkings } from "../../../../store/parkingsSlice";
import {
  ApartmentTransaction,
  ApartmentOtherItems,
} from "../../../../@types/apartmenttransaction";
import { ParkingTransaction } from "../../../../@types/parkingtransaction";
import moment, { Moment } from "moment";
import axios from "axios";
import ReportsExportPDFSimple from "../ExportExcelPDF/ReportsExportPDFSimple";
import { PDFDownloadLink, pdf } from "@react-pdf/renderer";
import { message } from "antd";
import { useTranslation } from "react-i18next";
import { User } from "../../../../@types/user";

const CloneReportTransactionsSimple: React.FC = () => {
  const [ApartmentCosts, setApartmentCosts] = useState(0);
  const { ownerId } = useParams();
  let [searchParams] = useSearchParams();

  const dispatch = useDispatch<AppDispatch>();

  const [bookingSources, setBookingSources] = useState<Array<any>>([]);

  const [apartmentTransactionPeriodFrom, setApartmentTransactionPeriodFrom] =
    useState<Moment | null>(
      searchParams.get("apartmentTransactionPeriodFrom")
        ? moment(searchParams.get("apartmentTransactionPeriodFrom"))
        : moment().subtract(1, "month").startOf("month")
    );
  const [apartmentTransactionPeriodTo, setApartmentTransactionPeriodTo] =
    useState<Moment | null>(
      searchParams.get("apartmentTransactionPeriodTo")
        ? moment(searchParams.get("apartmentTransactionPeriodTo")).subtract(
            10,
            "hours"
          )
        : moment().subtract(1, "month").endOf("month")
    );
  const [parkingTransactionPeriodFrom, setParkingTransactionPeriodFrom] =
    useState<Moment | null>(
      searchParams.get("parkingTransactionPeriodFrom")
        ? moment(searchParams.get("parkingTransactionPeriodFrom"))
        : moment().subtract(1, "month").startOf("month")
    );
  const [parkingTransactionPeriodTo, setParkingTransactionPeriodTo] =
    useState<Moment | null>(
      searchParams.get("parkingTransactionPeriodTo")
        ? moment(searchParams.get("parkingTransactionPeriodTo")).subtract(
            10,
            "hours"
          )
        : moment().subtract(1, "month").endOf("month")
    );
  const [filterApartments, setFilterApartments] = useState<any>([]);
  const [filterParkings, setFilterParkings] = useState([]);
  const [apartmentCalculations, setApartmentCalculations] = useState([]);
  const [apartmentOtherItems, setApartmentOtherItems] = useState<
    Array<ApartmentOtherItems>
  >([]);
  const [parkingCalculations, setParkingCalculations] = useState([]);
  const [isSubmitting, setIsSumitting] = useState(false);
  const [t] = useTranslation("common");

  const apartments = useSelector(
    (state: RootState) => state.apartments.apartments
  );
  const parkings = useSelector((state: RootState) => state.parkings.parkings);

  const curUser = useSelector((state: RootState) => state.common.curUser);
  const user = useSelector((state: RootState) => state.auth.user);
  const users: Array<User> = useSelector(
    (state: RootState) => state.users.owners
  );

  const [selectedACsRowKeys, setSelectedACsRowKeys] = useState<Array<any>>([]);
  const [selectedPCsRowKeys, setSelectedPCsRowKeys] = useState<Array<any>>([]);
  const [selectedAOsRowKeys, setSelectedAOsRowKeys] = useState<Array<any>>([]);

  const fetchBookingSources = async () => {
    try {
      const res = await axios
        .get("/source-commisions/booking-sources")
        .then((res) => res.data);

      setBookingSources(
        res.map((row: any) => {
          return { value: row?.BookingSource };
        })
      );
    } catch (err) {
      console.log(err);
    }
  };

  const apartmentColumns: ColumnsType<ApartmentTransaction> = [
    {
      title: t("transactions.Apartment Transactions.table.Apartment name"),
      dataIndex: "RoomName",
      width: 150,
      render: (
        RoomName: string,
        record: ApartmentTransaction,
        index: number
      ) => {
        return (
          <Select
            value={RoomName}
            onChange={(value) => {
              let newACs = [...apartmentCalculations] as any;
              let newRecord = { ...record };
              newRecord["RoomName"] = value;
              newACs[index] = newRecord;
              setApartmentCalculations(newACs);
            }}
          >
            {apartments.map((apartment: any) => (
              <Select.Option
                key={apartment.RoomName}
                value={apartment.RoomName}
              >
                {apartment.RoomName}
              </Select.Option>
            ))}
          </Select>
        );
      },
    },
    {
      title: t("transactions.Apartment Transactions.table.Date From"),
      dataIndex: "DateFrom",
      width: 150,
      render: (
        DateFrom: string,
        record: ApartmentTransaction,
        index: number
      ) => {
        return (
          <DatePicker
            value={moment(DateFrom)}
            onChange={(value) => {
              let newACs = [...apartmentCalculations] as any;
              let newRecord = { ...record };
              newRecord["DateFrom"] = moment(value).format("YYYY-MM-DD");
              newACs[index] = newRecord;
              setApartmentCalculations(newACs);
            }}
          />
        );
      },
    },
    {
      title: t("transactions.Apartment Transactions.table.Date To"),
      dataIndex: "DateTo",
      width: 150,
      render: (DateTo: string, record: ApartmentTransaction, index: number) => {
        return (
          <DatePicker
            value={moment(DateTo)}
            onChange={(value) => {
              let newACs = [...apartmentCalculations] as any;
              let newRecord = { ...record };
              newRecord["DateTo"] = moment(value).format("YYYY-MM-DD");
              newACs[index] = newRecord;
              setApartmentCalculations(newACs);
            }}
          />
        );
      },
    },
    {
      title: t("transactions.Apartment Transactions.table.Nights"),
      dataIndex: "Nights",
      width: 110,
      render: (Nights: number, record: ApartmentTransaction, index: number) => {
        return (
          <InputNumber
            value={Nights}
            onChange={(value) => {
              let newACs = [...apartmentCalculations] as any;
              let newRecord = { ...record };
              newRecord["Nights"] = value;
              newACs[index] = newRecord;
              setApartmentCalculations(newACs);
            }}
          />
        );
      },
    },
    {
      title: t("transactions.Apartment Transactions.table.Price Accomodation"),
      dataIndex: "PriceAccomodationPerNight",
      width: 160,
      render: (
        PriceAccomodationPerNight: number,
        record: ApartmentTransaction,
        index: number
      ) => {
        return (
          <InputNumber
            value={PriceAccomodationPerNight}
            onChange={(value) => {
              let newACs = [...apartmentCalculations] as any;
              let newRecord = { ...record };
              newRecord["PriceAccomodationPerNight"] = value;
              newACs[index] = newRecord;
              setApartmentCalculations(newACs);
            }}
          />
        );
      },
    },
    {
      title: t(
        "transactions.Apartment Transactions.table.Price Minus Src Commission"
      ),
      dataIndex: "PriceMinusSourceCommisionPerNight",
      width: 160,
      render: (
        PriceMinusSourceCommisionPerNight: number,
        record: ApartmentTransaction,
        index: number
      ) => {
        return (
          <InputNumber
            value={PriceMinusSourceCommisionPerNight}
            onChange={(value) => {
              let newACs = [...apartmentCalculations] as any;
              let newRecord = { ...record };
              newRecord["PriceMinusSourceCommisionPerNight"] = value;
              newACs[index] = newRecord;
              setApartmentCalculations(newACs);
            }}
          />
        );
      },
    },
    {
      title: t("transactions.Apartment Transactions.table.Price Minus Tax"),
      dataIndex: "PriceMinusTaxPerNight",
      width: 160,
      render: (
        PriceMinusTaxPerNight: number,
        record: ApartmentTransaction,
        index: number
      ) => {
        return (
          <InputNumber
            value={PriceMinusTaxPerNight}
            onChange={(value) => {
              let newACs = [...apartmentCalculations] as any;
              let newRecord = { ...record };
              newRecord["PriceMinusTaxPerNight"] = value;
              newACs[index] = newRecord;
              setApartmentCalculations(newACs);
            }}
          />
        );
      },
    },
    {
      title: t(
        "transactions.Apartment Transactions.table.Price Minus Breakfast"
      ),
      dataIndex: "PriceMinusBreakfast",
      width: 160,
      render: (
        PriceMinusBreakfast: number,
        record: ApartmentTransaction,
        index: number
      ) => {
        return (
          <InputNumber
            value={Number(PriceMinusBreakfast).toFixed(2)}
            onChange={(value) => {
              let newACs = [...apartmentCalculations] as any;
              let newRecord = { ...record };
              newRecord["PriceMinusBreakfast"] = Number(value);
              newACs[index] = newRecord;
              setApartmentCalculations(newACs);
            }}
          />
        );
      },
    },
    {
      title: t(
        "transactions.Apartment Transactions.table.Price Minus BH Commission"
      ),
      dataIndex: "PriceMinusBHCommision",
      width: 160,
      render: (
        PriceMinusBHCommision: number,
        record: ApartmentTransaction,
        index: number
      ) => {
        return (
          <InputNumber
            value={Number(PriceMinusBHCommision).toFixed(2)}
            onChange={(value) => {
              let newACs = [...apartmentCalculations] as any;
              let newRecord = { ...record };
              newRecord["PriceMinusBHCommision"] = Number(value);
              newACs[index] = newRecord;
              setApartmentCalculations(newACs);
            }}
          />
        );
      },
    },
    {
      title: t("transactions.Apartment Transactions.table.Booking Src"),
      dataIndex: "BookingSource",
      width: 140,
      render: (
        BookingSource: string,
        record: ApartmentTransaction,
        index: number
      ) => {
        return (
          <AutoComplete
            value={BookingSource}
            className="w-full"
            onChange={(value) => {
              let newACs = [...apartmentCalculations] as any;
              let newRecord = { ...record };
              newRecord["BookingSource"] = value;
              newACs[index] = newRecord;
              setApartmentCalculations(newACs);
            }}
            options={bookingSources}
            filterOption={(inputValue, option: any) =>
              option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !==
              -1
            }
          />
        );
      },
    },
  ];

  const apartmentOtherItemsColumns: ColumnsType<ApartmentOtherItems> = [
    {
      title: t("transactions.Other items.Other items"),
      dataIndex: "ItemName",
      width: 150,
    },
    {
      title: t("transactions.Other items.Fee"),
      dataIndex: "Fee",
      width: 150,
      render: (Fee: number, record: ApartmentOtherItems, index: number) => {
        return (
          <InputNumber
            value={Number(Fee).toFixed(2) || 0}
            onChange={(value) => {
              let newAOs = [...apartmentOtherItems] as any;
              let newRecord = { ...record };
              newRecord["Fee"] = Number(value);
              newAOs[index] = newRecord;
              setApartmentOtherItems(newAOs);
            }}
          />
        );
      },
    },
    {
      title: t("transactions.Other items.Count"),
      dataIndex: "Count",
      width: 150,
      render: (Count: number, record: ApartmentOtherItems, index: number) => {
        return (
          <InputNumber
            value={Number(Count).toFixed(2) || 0}
            onChange={(value) => {
              let newAOs = [...apartmentOtherItems] as any;
              let newRecord = { ...record };
              newRecord["Count"] = Number(value);
              newAOs[index] = newRecord;
              setApartmentOtherItems(newAOs);
            }}
          />
        );
      },
    },
    {
      title: t("transactions.Other items.Fee Minus BH Commission"),
      dataIndex: "FeeMinusBHCommission",
      width: 150,
      render: (
        FeeMinusBHCommission: number,
        record: ApartmentOtherItems,
        index: number
      ) => {
        return (
          <InputNumber
            value={Number(FeeMinusBHCommission).toFixed(2) || 0}
            onChange={(value) => {
              let newAOs = [...apartmentOtherItems] as any;
              let newRecord = { ...record };
              newRecord["FeeMinusBHCommission"] = Number(value);
              newAOs[index] = newRecord;
              setApartmentOtherItems(newAOs);
            }}
          />
        );
      },
    },
    {
      title: t("transactions.Other items.Total"),
      dataIndex: "Total",
      width: 150,
      render: (Total: number, record: ApartmentOtherItems, index: number) => {
        return (
          <InputNumber
            value={Number(Total).toFixed(2) || 0}
            onChange={(value) => {
              let newAOs = [...apartmentOtherItems] as any;
              let newRecord = { ...record };
              newRecord["Total"] = Number(value);
              newAOs[index] = newRecord;
              setApartmentOtherItems(newAOs);
            }}
          />
        );
      },
    },
  ];

  const parkingColumns: ColumnsType<ParkingTransaction> = [
    {
      title: t("transactions.Parking Transactions.table.Parking name"),
      dataIndex: "ParkingName",
      width: 160,
      render: (
        ParkingName: string,
        record: ParkingTransaction,
        index: number
      ) => {
        return (
          <Select
            value={ParkingName}
            onChange={(value) => {
              let newPCs = [...parkingCalculations] as any;
              let newRecord = { ...record };
              newRecord["ParkingName"] = value;
              newPCs[index] = newRecord;
              setParkingCalculations(newPCs);
            }}
          >
            {parkings.map((parking: any) => (
              <Select.Option
                key={parking.ParkingName}
                value={parking.ParkingName}
              >
                {parking.ParkingName}
              </Select.Option>
            ))}
          </Select>
        );
      },
    },
    {
      title: (
        <div>{t("transactions.Parking Transactions.table.Date From")}</div>
      ),
      dataIndex: "DateFrom",
      width: 160,
      render: (DateFrom: string, record: ParkingTransaction, index: number) => {
        return (
          <DatePicker
            value={moment(DateFrom)}
            onChange={(value) => {
              let newPCs = [...parkingCalculations] as any;
              let newRecord = { ...record };
              newRecord["DateFrom"] = moment(value).format("YYYY-MM-DD");
              newPCs[index] = newRecord;
              setParkingCalculations(newPCs);
            }}
          />
        );
      },
    },
    {
      title: <div>{t("transactions.Parking Transactions.table.Date To")}</div>,
      dataIndex: "DateTo",
      width: 150,
      render: (DateTo: string, record: ParkingTransaction, index: number) => {
        return (
          <DatePicker
            value={moment(DateTo)}
            onChange={(value) => {
              let newPCs = [...parkingCalculations] as any;
              let newRecord = { ...record };
              newRecord["DateTo"] = moment(value).format("YYYY-MM-DD");
              newPCs[index] = newRecord;
              setParkingCalculations(newPCs);
            }}
          />
        );
      },
    },
    {
      title: t("transactions.Parking Transactions.table.Nights"),
      dataIndex: "ParkingNights",
      width: 150,
      render: (
        ParkingNights: number,
        record: ParkingTransaction,
        index: number
      ) => {
        return (
          <InputNumber
            value={ParkingNights}
            onChange={(value) => {
              let newPCs = [...parkingCalculations] as any;
              let newRecord = { ...record };
              newRecord["ParkingNights"] = value;
              newPCs[index] = newRecord;
              setParkingCalculations(newPCs);
            }}
          />
        );
      },
    },
    {
      title: (
        <div>
          {t("transactions.Parking Transactions.table.Price Minus Tax")}
        </div>
      ),
      dataIndex: "ParkingPriceMinusTax",
      width: 150,
      render: (
        ParkingPriceMinusTax: number,
        record: ParkingTransaction,
        index: number
      ) => {
        return (
          <InputNumber
            value={Number(ParkingPriceMinusTax).toFixed(2)}
            onChange={(value) => {
              let newPCs = [...parkingCalculations] as any;
              let newRecord = { ...record };
              newRecord["ParkingPriceMinusTax"] = Number(value);
              newPCs[index] = newRecord;
              setParkingCalculations(newPCs);
            }}
          />
        );
      },
    },
    {
      title: (
        <div>
          {t(
            "transactions.Parking Transactions.table.Price Minus BH Commission"
          )}
        </div>
      ),
      dataIndex: "ParkingPriceMinusBHCommision",
      width: 150,
      render: (
        ParkingPriceMinusBHCommision: number,
        record: ParkingTransaction,
        index: number
      ) => {
        return (
          <InputNumber
            value={Number(ParkingPriceMinusBHCommision).toFixed(2)}
            onChange={(value) => {
              let newPCs = [...parkingCalculations] as any;
              let newRecord = { ...record };
              newRecord["ParkingPriceMinusBHCommision"] = Number(value);
              newPCs[index] = newRecord;
              setParkingCalculations(newPCs);
            }}
          />
        );
      },
    },
  ];

  const fetchApartmentCalculations = async () => {
    try {
      const res = await axios
        .get("/apartment-transactions/reports/", {
          params: {
            from: apartmentTransactionPeriodFrom
              ? apartmentTransactionPeriodFrom.format("YYYY-MM-DD")
              : "",
            to: apartmentTransactionPeriodTo
              ? apartmentTransactionPeriodTo.format("YYYY-MM-DD")
              : "",
            apartments: filterApartments,
            ownerId,
          },
        })
        .then((res) => res.data);

      setApartmentCalculations(res.transactions);
      setApartmentOtherItems([
        {
          ItemName: t("Service"),
          Fee: res.otherItems.ServiceFee || 0,
          Count: res.otherItems.ServiceCount || 0,
          FeeMinusBHCommission: res.otherItems.ServiceFeeMinusBHCommision || 0,
          Total: res.otherItems.ServiceTotal || 0,
        },
        {
          ItemName: t("Towel Service"),
          Fee: res.otherItems.TowelServiceFee || 0,
          Count: res.otherItems.TowelServiceFeeCount || 0,
          FeeMinusBHCommission: res.otherItems.TowelServiceMinusBHCommision || 0,
          Total: res.otherItems.TowelServiceTotal || 0,
        },
        {
          ItemName: t("Cleaning"),
          Fee: res.otherItems.CleaningFee || 0,
          Count: res.otherItems.CleaningFeeCount || 0,
          FeeMinusBHCommission: res.otherItems.CleaningFeeMinusBHCommision || 0,
          Total: res.otherItems.CleaningTotal || 0,
        },
        {
          ItemName: t("Owner Cleaning"),
          Fee: res.otherItems.OwnerCleaningFee || 0,
          Count: res.otherItems.OwnerCleaningFeeCount || 0,
          FeeMinusBHCommission: 0,
          Total: res.otherItems.OwnerCleaningTotal || 0,
        },
        {
          ItemName: t("transactions.Apartment Costs"),
          Fee: 0,
          Count: 0,
          FeeMinusBHCommission: 0,
          Total: Number(ApartmentCosts).toFixed(2),
        },
      ]);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchParkingCalculations = async () => {
    try {
      const res = await axios
        .get("/parking-transactions/reports", {
          params: {
            from: parkingTransactionPeriodFrom
              ? parkingTransactionPeriodFrom.format("YYYY-MM-DD")
              : "",
            to: parkingTransactionPeriodTo
              ? parkingTransactionPeriodTo.format("YYYY-MM-DD")
              : "",
            parkings: filterParkings,
            ownerId,
          },
        })
        .then((res) => res.data);

      setParkingCalculations(res);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchApartmentCalculations();
  }, [ApartmentCosts]);

  useEffect(() => {
    setSelectedACsRowKeys([]);

    dispatch(loadApartments({ search: "", ownerId }));
    dispatch(loadParkings({ search: "", ownerId }));

    fetchBookingSources();
  }, []);

  useEffect(() => {
    fetchListCost();
    fetchApartmentCalculations();
  }, [filterApartments, t]);

  useEffect(() => {
    fetchListCost();
    fetchParkingCalculations();
  }, [filterParkings]);

  useEffect(() => {
    fetchListCost();
    fetchApartmentCalculations();
    fetchParkingCalculations();
  }, [apartmentTransactionPeriodFrom, apartmentTransactionPeriodTo]);

  useEffect(() => {
    fetchParkingCalculations();
  }, [parkingTransactionPeriodFrom, parkingTransactionPeriodTo]);

  const onPeriodChange = (dates: any, type: string) => {
    const from = dates ? dates[0] : null;
    const to = dates ? dates[1] : null;

    setParkingTransactionPeriodFrom(from);
    setParkingTransactionPeriodTo(to);

    if (type === "apartment") {
      setApartmentTransactionPeriodFrom(from);
      setApartmentTransactionPeriodTo(to);
    }
  };

  const ReportsExportPDFRender: React.FC = () => {
    return (
      <ReportsExportPDFSimple
        dateFrom={apartmentTransactionPeriodFrom}
        dateTo={apartmentTransactionPeriodTo}
        apartmentCalculations={apartmentCalculations}
        apartmentOtherItems={apartmentOtherItems}
        parkingCalculations={parkingCalculations}
        curUser={curUser}
        t={t}
      />
    );
  };

  const finalTotalTrans = (
    apartmentCalculations.reduce(
      (pVal, cVal: ApartmentTransaction) =>
        Number(pVal) + Number(cVal.PriceMinusBHCommision),
      0
    ) -
    apartmentOtherItems.reduce(
      (pVal, cVal: any) => Number(pVal) + Number(cVal.Total),
      0
    ) +
    parkingCalculations.reduce(
      (pVal, cVal: ParkingTransaction) =>
        Number(pVal) + Number(cVal.ParkingPriceMinusBHCommision),
      0
    )
  ).toFixed(2);

  const sentSummaries = async () => {
    const businessSegments = await axios
      .get(`/sentsummaries/bs/`)
      .then((res) => res.data);
    const owner_ids = apartments.map((apartment: any) => apartment.OwnerID);
    const RoomName = Array.from(
      new Set(apartmentCalculations.map((apartment: any) => apartment.RoomName))
    ).join(",");
    const ParkingName = Array.from(
      new Set(parkingCalculations.map((parking: any) => parking.ParkingName))
    ).join(",");
    if (RoomName.length > 0 || ParkingName.length > 0) {
      const uniq_bs = Array.from(
        new Set(
          apartmentCalculations.map((apartmentCalc: any) => {
            const arr: { RoomName: string; BusinessSegment: string }[] =
              apartments.filter((apartment: any) => {
                return apartment.RoomName === apartmentCalc.RoomName;
              });
            return arr[0].BusinessSegment;
          })
        )
      );

      const res = await axios
        .post(
          `/sentsummaries/create`,
          JSON.stringify({
            SentON: new Date(),
            SentBy: `${user?.FirstName} ${user?.LastName}`,
            RoomName: RoomName,
            ParkingName: ParkingName,
            DateFrom: apartmentTransactionPeriodFrom
              ? apartmentTransactionPeriodFrom
              : "0001-01-01",
            DateTo: apartmentTransactionPeriodTo
              ? apartmentTransactionPeriodTo
              : "9999-12-31",
            OwnerName: users
              .filter((user: any) => owner_ids.includes(user.OwnerID))
              .map((user: any) => `${user?.FirstName} ${user?.LastName}`)
              .join(","),
            ApartmentTotal: apartmentCalculations.reduce(
              (pVal, cVal: ApartmentTransaction) =>
                Number(pVal) + Number(cVal.PriceMinusBHCommision),
              0
            ),
            CostTotal: (() => {
              let total = 0;
              apartmentOtherItems.forEach((row) => {
                total += Number(row.Total);
              });
              return total;
            })(),
            parkingTotal: parkingCalculations.reduce(
              (pVal, cVal: ParkingTransaction) =>
                Number(pVal) + Number(cVal.ParkingPriceMinusBHCommision),
              0
            ),
            FinalTotal: Number(finalTotalTrans),
            BusinessSegment: Array.from(
              new Set(
                apartmentCalculations.map((apartmentCalc: any) => {
                  const arr: { RoomName: string; BusinessSegment: string }[] =
                    apartments.filter((apartment: any) => {
                      return apartment.RoomName === apartmentCalc.RoomName;
                    });
                  return arr[0].BusinessSegment;
                })
              )
            ).join(","),
            SourceAccount: businessSegments.filter((bs: any) => {
              return uniq_bs.includes(bs.BusinessSegment);
            })[0].AccountNumber,
            DestinationAccount: users
              .filter((user: any) => owner_ids.includes(user.OwnerID))
              .map((user: any) => `${user?.BankAccount}`)[0],
          }),
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => res.data);
      return res;
    }
  };

  const sendEmail = async () => {
    const myPdf = pdf(<ReportsExportPDFRender />);
    const blob = await myPdf.toBlob(); /*create blob*/

    var file = new File([blob], "pdfname.pdf", {
      lastModified: new Date().getTime(),
    }); /*create file*/

    try {
      const formData = new FormData();
      formData.append("Attachment", file);
      formData.append("DateFrom", apartmentTransactionPeriodFrom as any);
      formData.append("DateTo", apartmentTransactionPeriodTo as any);
      formData.append(
        "FileName",
        `Transactions report (${curUser?.FirstName} ${curUser?.LastName}).pdf`
      );

      setIsSumitting(true);

      const res = await axios
        .post(`/apartment-transactions/reports/${curUser?.OwnerID}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => res.data);

      setIsSumitting(false);
      if (res?.success) {
        message.success(
          t("Email has been sent successfully. Please check your inbox.")
        );
      } else {
        message.error(
          t(
            "Wrong email or something went wrong on server. Please try again later."
          )
        );
      }
    } catch (err: any) {
      console.log(err.message);
      setIsSumitting(false);
      message.error(
        t(
          "Wrong email or something went wrong on server. Please try again later."
        )
      );
    }
    sentSummaries();
  };

  const confirmDelete = () => {
    Modal.confirm({
      title: (
        <div className="text-white text-center">
          {t("Do you want to delete")} ?
        </div>
      ),
      okText: t("YES"),
      icon: null,
      cancelText: t("NO"),
      width: 340,
      okButtonProps: {
        className: "btn-yellow hvr-float-shadow w-32 h-10 text-xs ml-3.5",
      },
      cancelButtonProps: {
        className: "btn-danger hvr-float-shadow w-32 h-10 text-xs",
      },
      onOk: async () => {
        const apartmentIds = selectedACsRowKeys
          .map((key) => key.split("_")[0])
          .filter((id) => Boolean(id));

        const parkingIds = selectedPCsRowKeys
          .map((key) => key.split("_")[0])
          .filter((id) => Boolean(id));

        const otherItemsIds = selectedAOsRowKeys
          .map((key) => key.split("_")[0])
          .filter((ItemName) => Boolean(ItemName));

        if (apartmentIds.length > 0) {
          const newApartmentCalculations = apartmentCalculations.filter(
            (row: any, index) =>
              selectedACsRowKeys.indexOf(`${row.RowID}_${index}`) === -1
          );
          setApartmentCalculations(newApartmentCalculations);
        }

        if (parkingIds.length > 0) {
          const newParkingCalculations = parkingCalculations.filter(
            (row: any, index) =>
              selectedPCsRowKeys.indexOf(`${row.RowID}_${index}`) === -1
          );
          setParkingCalculations(newParkingCalculations);
        }

        if (otherItemsIds.length > 0) {
          const newApartmentOtherItems = apartmentOtherItems.filter(
            (row: any, index) =>
              selectedAOsRowKeys.indexOf(`${row.ItemName}_${index}`) === -1
          );
          setApartmentOtherItems(newApartmentOtherItems);
        }

        setSelectedACsRowKeys([]);
        setSelectedPCsRowKeys([]);
        setSelectedAOsRowKeys([]);
      },
      onCancel() {},
    });
  };

  const deleteApartmentTransactions = () => {
    if (
      selectedACsRowKeys.length === 0 &&
      selectedPCsRowKeys.length === 0 &&
      selectedAOsRowKeys.length === 0
    ) {
      message.warning(t("Select rows first."));
      return;
    }

    confirmDelete();
  };

  const addParkingTransaction = () => {
    if (parkings.length === 0) {
      message.warning("There are no parkings. Please add parking first.");
      return;
    }
    const emptyParkingTransaction = {
      RowID: (
        String(new Date().getTime()) + String(Math.round(Math.random() * 10000))
      ).slice(-10),
      ParkingName: (parkings[0] as any).ParkingName,
      DateFrom: moment(new Date()).format("YYYY-MM-DD"),
      DateTo: moment(new Date()).format("YYYY-MM-DD"),
      ParkingNights: 0,
      ParkingPriceMinusTax: 0,
      ParkingPriceMinusBHCommision: 0,
    };

    setParkingCalculations([
      ...parkingCalculations,
      emptyParkingTransaction,
    ] as any);
  };
  const addApartmentTransaction = () => {
    if (apartments.length === 0) {
      message.warning("There are no apartments. Please add apartment first.");
      return;
    }
    const emptyApartmentTransaction = {
      RowID: (
        String(new Date().getTime()) + String(Math.round(Math.random() * 10000))
      ).slice(-10),
      RoomName: (apartments[0] as any).RoomName,
      DateFrom: moment(new Date()).format("YYYY-MM-DD"),
      DateTo: moment(new Date()).format("YYYY-MM-DD"),
      Nights: 0,
      PriceMinusTax: 0,
      PriceMinusBHCommision: 0,
      BHCommision: "0.00",
      BookingSource: "...",
      BreakFastUnitPrice: "0.00",
      BreakfastPrice: 0,
      BreakfastPricePerNight: "0.00",
      BreakfastQty: "0.00",
      CleaningFee: "0.00",
      CleaningFeeMinusBHCommision: "0.00",
      OwnerCleaningFee: "0.00",
      OwnerID: curUser?.OwnerID,
      PriceAccomodation: "0.00",
      PriceAccomodationPerNight: "0.00",
      PriceMinusBreakfast: 0,
      PriceMinusBreakfastPerNight: 0,
      PriceMinusSourceCommision: "0.00",
      PriceMinusSourceCommisionPerNight: "0.00",
      PriceMinusTaxPerNight: "0.00",
      PriceTotal: "0.00",
      ReservationID: (
        String(new Date().getTime()) + String(Math.round(Math.random() * 10000))
      ).slice(-10),
      Service: 1,
      ServiceFee: "0.00",
      ServiceFeeMinusBHCommision: "0.00",
    };

    setApartmentCalculations([
      ...apartmentCalculations,
      emptyApartmentTransaction,
    ] as any);
  };

  const fetchListCost = async () => {
    var TotalApartmentPrice = 0;
    try {
      const res = await axios
        .get("/costs/list", {
          params: {
            from: apartmentTransactionPeriodFrom
              ? apartmentTransactionPeriodFrom.format("YYYY-MM-DD")
              : "",
            to: apartmentTransactionPeriodTo
              ? apartmentTransactionPeriodTo.format("YYYY-MM-DD")
              : "",
            ...(curUser?.OwnerID ? { ownerId: curUser?.OwnerID } : {}),
          },
        })
        .then((res) => res.data);

      res.forEach((itm: any) => {
        if (
          filterApartments.includes(itm.RoomName) ||
          filterApartments.length === 0
        ) {
          TotalApartmentPrice += Number(itm.OwnerCostValue);
        }
      });

      setApartmentCosts(TotalApartmentPrice);
    } catch (err) {
      console.log(err);
    }
  };

  const disabledDate = (current: any) => {
    // Can not select days before today and today
    return current && current < moment("2021-12-31").endOf("day");
  };

  return (
    <div className="container-xl mx-auto px-3 h-full pt-7">
      <div className="flex flex-col justify-between mb-10">
        <h2 className="text-xl font-bold leading-7 text-gray-900 sm:text-2xl sm:truncate">
          CHANGE REPORT PAGE is clone of transactions
        </h2>
        <div className="mt-5 border-b-2 mb-2 border-gray-700 justify-between md:flex block">
          <div className="flex font-bold text-xl text-c-blue px-3">
            {t("transactions.Apartment Transactions.Apartment Transactions")}
          </div>
          <div className="flex items-center mb-2">
            <span className="font-bold mr-4">{t("transactions.Period")}:</span>

            <DatePicker.RangePicker
              ranges={{
                "This Month": [
                  moment().startOf("month"),
                  moment().endOf("month"),
                ],
              }}
              disabledDate={
                user?.Role === "admin" || user?.Role === "super-admin"
                  ? () => {}
                  : disabledDate
              }
              value={[
                apartmentTransactionPeriodFrom,
                apartmentTransactionPeriodTo,
              ]}
              onChange={(dates) => onPeriodChange(dates, "apartment")}
            />

            <Select
              mode="multiple"
              allowClear
              placeholder="All"
              className="w-40 ml-3"
              onChange={(value) => setFilterApartments(value)}
              value={filterApartments}
            >
              {apartments.map((apartment: any) => (
                <Select.Option
                  key={apartment.RoomName}
                  value={apartment.RoomName}
                >
                  {apartment.RoomName}
                </Select.Option>
              ))}
            </Select>
          </div>
        </div>

        <div className="max-w-full overflow-auto">
          <Table
            rowKey={(record, index) => {
              return `${record.RowID}_${index}`;
            }}
            rowSelection={{
              selectedRowKeys: selectedACsRowKeys,
              onChange: (values) => {
                setSelectedACsRowKeys(values);
              },
            }}
            columns={apartmentColumns}
            dataSource={apartmentCalculations}
            rowClassName="hover:bg-white hover:bg-opacity-10"
            className="border flex-grow mb-5"
            pagination={false}
            scroll={{ y: 500 }}
            summary={() => {
              let summaryData = {
                Nights: 0,
                PriceAccomodationPerNight: 0,
                PriceMinusSourceCommisionPerNight: 0,
                PriceMinusTaxPerNight: 0,
                PriceMinusBreakfast: 0,
                PriceMinusBHCommision: 0,
              };

              let PriceAccomodationPerNight = 0;
              let PriceMinusSourceCommisionPerNight = 0;
              let PriceMinusTaxPerNight = 0;

              apartmentCalculations.forEach((row: ApartmentTransaction) => {
                summaryData.Nights += Number(row.Nights);
                PriceAccomodationPerNight += Number(
                  row.PriceAccomodationPerNight
                );
                PriceMinusSourceCommisionPerNight += Number(
                  row.PriceMinusSourceCommisionPerNight
                );
                PriceMinusTaxPerNight += Number(row.PriceMinusTaxPerNight);
                summaryData.PriceMinusBreakfast += Number(
                  row.PriceMinusBreakfast
                );
                summaryData.PriceMinusBHCommision += Number(
                  row.PriceMinusBHCommision
                );
              });

              const rowCount = apartmentCalculations.length;
              summaryData.PriceAccomodationPerNight =
                rowCount > 0
                  ? Number((PriceAccomodationPerNight / rowCount).toFixed(2))
                  : 0;
              summaryData.PriceMinusSourceCommisionPerNight =
                rowCount > 0
                  ? Number(
                      (PriceMinusSourceCommisionPerNight / rowCount).toFixed(2)
                    )
                  : 0;
              summaryData.PriceMinusTaxPerNight =
                rowCount > 0
                  ? Number((PriceMinusTaxPerNight / rowCount).toFixed(2))
                  : 0;

              return (
                <Table.Summary fixed={"bottom"}>
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0}>
                      <FontAwesomeIcon
                        icon={faPlusCircle}
                        className="cursor-pointer"
                        onClick={addApartmentTransaction}
                      />
                    </Table.Summary.Cell>
                    <Table.Summary.Cell
                      index={0}
                      colSpan={4}
                      className="font-bold"
                    >
                      {t("transactions.Final Total")}
                    </Table.Summary.Cell>

                    <Table.Summary.Cell index={1} className="font-bold">
                      {Number(summaryData.Nights)}
                    </Table.Summary.Cell>

                    <Table.Summary.Cell index={1} className="font-bold">
                      {Number(summaryData.PriceAccomodationPerNight).toFixed(2)}
                    </Table.Summary.Cell>

                    <Table.Summary.Cell index={1} className="font-bold">
                      {Number(
                        summaryData.PriceMinusSourceCommisionPerNight
                      ).toFixed(2)}
                    </Table.Summary.Cell>

                    <Table.Summary.Cell index={1} className="font-bold">
                      {Number(summaryData.PriceMinusTaxPerNight).toFixed(2)}
                    </Table.Summary.Cell>

                    <Table.Summary.Cell index={2} className="font-bold">
                      {Number(summaryData.PriceMinusBreakfast).toFixed(2)}
                    </Table.Summary.Cell>

                    <Table.Summary.Cell
                      index={3}
                      colSpan={2}
                      className="font-bold"
                    >
                      {Number(summaryData.PriceMinusBHCommision).toFixed(2)}
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </Table.Summary>
              );
            }}
          />
        </div>

        <div className="max-w-full overflow-auto">
          <Table
            rowKey={(record, index) => {
              return `${record.ItemName}_${index}`;
            }}
            rowSelection={{
              selectedRowKeys: selectedAOsRowKeys,
              onChange: (values) => {
                setSelectedAOsRowKeys(values);
              },
            }}
            columns={apartmentOtherItemsColumns}
            dataSource={apartmentOtherItems}
            rowClassName="hover:bg-white hover:bg-opacity-10"
            className="border flex-grow"
            pagination={false}
            summary={() => {
              let summaryData = {
                Fee: 0,
                Count: 0,
                FeeMinusBHCommission: 0,
                Total: 0,
              };
              apartmentOtherItems.forEach((row) => {
                summaryData.Fee += Number(row.Fee);
                summaryData.Count += Number(row.Count);
                summaryData.FeeMinusBHCommission += Number(
                  row.FeeMinusBHCommission || 0
                );
                summaryData.Total += Number(row.Total);
              });

              return (
                <Table.Summary fixed={"bottom"}>
                  <Table.Summary.Row>
                    <Table.Summary.Cell
                      index={0}
                      colSpan={2}
                      className="font-bold"
                    >
                      {t("transactions.Final Total")}
                    </Table.Summary.Cell>

                    <Table.Summary.Cell index={1} className="font-bold">
                      {Number(summaryData.Fee).toFixed(2)}
                    </Table.Summary.Cell>

                    <Table.Summary.Cell index={1} className="font-bold">
                      {Number(summaryData.Count)}
                    </Table.Summary.Cell>

                    <Table.Summary.Cell index={2} className="font-bold">
                      {Number(summaryData.FeeMinusBHCommission).toFixed(2)}
                    </Table.Summary.Cell>

                    <Table.Summary.Cell index={3} className="font-bold">
                      {Number(summaryData.Total).toFixed(2)}
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </Table.Summary>
              );
            }}
          />
        </div>
      </div>

      <div className="flex flex-col justify-between">
        <div className="mt-8 border-b-2 mb-2 border-gray-700 flex justify-between">
          <div className="flex font-bold text-xl text-c-blue px-3">
            {t("transactions.Parking Transactions.Parking Transactions")}
          </div>

          <div className="flex items-center mb-2">
            <span className="font-bold mr-4">{t("transactions.Period")}:</span>

            <DatePicker.RangePicker
              ranges={{
                "Last Month": [
                  moment().subtract(1, "month").startOf("month"),
                  moment().subtract(1, "month").endOf("month"),
                ],
                "This Month": [
                  moment().startOf("month"),
                  moment().endOf("month"),
                ],
              }}
              value={[parkingTransactionPeriodFrom, parkingTransactionPeriodTo]}
              disabledDate={
                user?.Role === "admin" || user?.Role === "super-admin"
                  ? () => {}
                  : disabledDate
              }
              onChange={(dates) => onPeriodChange(dates, "parking")}
            />

            <Select
              mode="multiple"
              allowClear
              placeholder="All"
              className="w-40 ml-3"
              onChange={(value) => setFilterParkings(value)}
              value={filterParkings}
            >
              {parkings.map((parking: any) => (
                <Select.Option
                  key={parking.ParkingName}
                  value={parking.ParkingName}
                >
                  {parking.ParkingName}
                </Select.Option>
              ))}
            </Select>
          </div>
        </div>

        <Table
          rowKey={(record, index) => {
            return `${record.RowID}_${index}`;
          }}
          rowSelection={{
            selectedRowKeys: selectedPCsRowKeys,
            onChange: (values) => {
              setSelectedPCsRowKeys(values);
            },
          }}
          columns={parkingColumns}
          dataSource={parkingCalculations}
          rowClassName="hover:bg-white hover:bg-opacity-10"
          className="border flex-grow"
          pagination={false}
          scroll={{ y: 500 }}
          summary={() => {
            let summaryData = {
              Nights: 0,
              ParkingPriceMinusTax: 0,
              ParkingPriceMinusBHCommision: 0,
            };
            parkingCalculations.forEach((row: ParkingTransaction) => {
              summaryData.Nights += Number(row.ParkingNights);
              summaryData.ParkingPriceMinusTax += Number(
                row.ParkingPriceMinusTax
              );
              summaryData.ParkingPriceMinusBHCommision += Number(
                row.ParkingPriceMinusBHCommision
              );
            });

            return (
              <Table.Summary fixed={"bottom"}>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}>
                    <FontAwesomeIcon
                      icon={faPlusCircle}
                      className="cursor-pointer"
                      onClick={addParkingTransaction}
                    />
                  </Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={1}
                    colSpan={3}
                    className="font-bold"
                  >
                    {t("transactions.Final Total")}
                  </Table.Summary.Cell>

                  <Table.Summary.Cell index={2} className="font-bold">
                    {Number(summaryData.Nights)}
                  </Table.Summary.Cell>

                  <Table.Summary.Cell index={3} className="font-bold">
                    {Number(summaryData.ParkingPriceMinusTax).toFixed(2)}
                  </Table.Summary.Cell>

                  <Table.Summary.Cell index={4} className="font-bold">
                    {Number(summaryData.ParkingPriceMinusBHCommision).toFixed(
                      2
                    )}
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            );
          }}
        />

        <div className="mt-2 mb-6 pt-4 border-t-2 border-gray-700">
          <div className="font-bold text-lg mb-8 flex">
            <div className="flex-grow">{t("Final Total Transactions")}: </div>
            <div style={{ width: "22%" }} className="flex-none">
              {(
                apartmentCalculations.reduce(
                  (pVal, cVal: ApartmentTransaction) =>
                    Number(pVal) + Number(cVal.PriceMinusBHCommision),
                  0
                ) -
                apartmentOtherItems.reduce(
                  (pVal, cVal: any) => Number(pVal) + Number(cVal.Total),
                  0
                ) +
                parkingCalculations.reduce(
                  (pVal, cVal: ParkingTransaction) =>
                    Number(pVal) + Number(cVal.ParkingPriceMinusBHCommision),
                  0
                )
              ).toFixed(2)}
            </div>
          </div>

          <div className="md:flex block justify-end">
            <Link
              to={`/reports/${curUser?.OwnerID}/simple`}
              className="btn-default hvr-float-shadow h-10 w-32 flex items-center justify-center  md:mb-0 mb-3"
            >
              {t("BACK")}
            </Link>

            <Button
              key="delete"
              className="btn-default hvr-float-shadow h-10 w-40 ml-auto"
              onClick={deleteApartmentTransactions}
            >
              {t("DELETE SELECTED")}
            </Button>

            <Button
              className="btn-default hvr-float-shadow h-10 w-40 ml-3"
              disabled={isSubmitting}
              onClick={sendEmail}
            >
              {isSubmitting && <FontAwesomeIcon icon={faSpinner} spin />}
              {!isSubmitting && t("SEND BY MAIL")}
            </Button>

            <PDFDownloadLink
              document={<ReportsExportPDFRender />}
              fileName={`Transactions report (${curUser?.FirstName} ${curUser?.LastName}).pdf`}
            >
              <Button
                onClick={() => {
                  sentSummaries();
                }}
                className="btn-default hvr-float-shadow h-10 w-40 ml-3"
              >
                {t("EXPORT PDF")}
              </Button>
            </PDFDownloadLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CloneReportTransactionsSimple;
