import type { Worksheet } from "exceljs";

interface ColumnList {
    key: string;
    header: string;
    width: number;
}

export function getColumnsForWorksheet(columnsList: ColumnList[]) {
    return columnsList.map((columnList) => {
        const {key, width} = columnList;
        const response = {key, width};
        return response;
    });
}


type TcolumnsForInsert = {
    [key: string]: string;
}
export function getColumnsForInsert(columnsList: ColumnList[]) {
    const result: TcolumnsForInsert = {};

    columnsList.forEach((columnList) => {
        const {header, key} = columnList;
            return result[key] = header;
    });

    return [result];
}

function addFormulaToCell(worksheet: Worksheet, cell: string, formula: string) {
    return worksheet.getCell(cell).value = { formula, date1904: false };
}

export function addSumToCells(columnsForSum: string[],  worksheet: Worksheet, startString: number, endString: number) {
    columnsForSum.forEach((column) => {
        const cell = column + endString;
        const sumRangeStart = column + startString;
        const sumRangeEnd = column + (endString - 1);

        const formula = `SUM(${sumRangeStart}:${sumRangeEnd}`;
        addFormulaToCell(worksheet, cell, formula);
      });

      return true;
}

export type TaddTotalString = {
    worksheet: Worksheet;
    insertAllRowsPosition: number;
    insertRowsLength: number;
    textColor: string;
    columnsForSum: string[];
}
export function addTotalString(options: TaddTotalString) {
    const {worksheet, insertAllRowsPosition, insertRowsLength, textColor, columnsForSum} = options;

    const backgroundColor = 'E6EEF9'

    const summaryDataPosition = insertAllRowsPosition + insertRowsLength;
    const summaryDataRow = worksheet.getRow(summaryDataPosition);
    summaryDataRow.fill = {
      type: 'pattern',
      pattern:'darkVertical',
      fgColor:{argb: backgroundColor},
    };
    summaryDataRow.font = { bold: true, size: 11.5, color: {argb: textColor} };
    summaryDataRow.height = 25;
    summaryDataRow.alignment = {
      vertical: "middle",
      horizontal: "left",
    };
    worksheet.getCell('A' + summaryDataPosition).value = 'Final Total';

    addSumToCells(columnsForSum, worksheet, insertAllRowsPosition, summaryDataPosition);

    return true;
}

export function parseToFloat(value: string | number) {
    if (value) {
        return parseFloat(value as string);
    }
    return value;
}
