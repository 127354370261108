import { Button } from "antd";
import ExcelJS from "exceljs";
import saveAs from "file-saver";
import { CostType } from "../../@types/cost";
import { useTranslation } from "react-i18next";

import moment, { Moment } from "moment";

type CProps = {
  rows: Array<CostType>;
  dateFrom: Moment | null;
  dateTo: Moment | null;
};

const CostExportExcel: React.FC<CProps> = (props) => {
  const [t] = useTranslation("common");

  const excelData = props.rows.map((row) => {
    return {
      CostTransID: row.CostTransID,
      CostType: row.CostType,
      Number: row.Number,
      Date: row.Date ? moment(row.Date).format("YYYY-MM-DD") : "",
      BookingDate: row.BookingDate
        ? moment(row.BookingDate).format("YYYY-MM-DD")
        : "",
      CostValue: row.CostValue,
      OwnerChargeSplit: row.OwnerChargeSplit,
      OwnerCostValue: row.OwnerCostValue,
      RoomName: row.RoomName,
      Comments: row.Comments,
    };
  });

  const exportExcel = async () => {
    try {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Parking Transactions", {
        views: [{ state: "frozen", ySplit: 1 }],
      });

      worksheet.columns = [
        {
          header: t("cost.table.CostTransID"),
          key: "CostTransID",
          width: 20,
        },
        {
          header: t("cost.table.CostType"),
          key: "CostType",
          width: 20,
        },
        {
          header: t("cost.table.Number"),
          key: "Number",
          width: 20,
        },
        {
          header: t("cost.table.Date"),
          key: "Date",
          width: 20,
        },
        {
          header: t("cost.table.BookingDate"),
          key: "BookingDate",
          width: 20,
        },
        {
          header: t("cost.table.CostValue"),
          key: "CostValue",
          width: 20,
        },
        {
          header: t("cost.table.OwnerChargeSplit"),
          key: "OwnerChargeSplit",
          width: 30,
        },
        {
          header: t("cost.table.OwnerCostValue"),
          key: "OwnerCostValue",
          width: 25,
        },
        {
          header: t("cost.table.RoomName"),
          key: "RoomName",
          width: 20,
        },
        {
          header: t("cost.table.Comments"),
          key: "Comments",
          width: 20,
        },
      ];

      worksheet.getColumn(1).alignment = {
        vertical: "bottom",
        horizontal: "left",
      };
      worksheet.getColumn(2).alignment = {
        vertical: "bottom",
        horizontal: "left",
      };

      worksheet.getRow(1).font = { bold: true, size: 14 };
      worksheet.getRow(1).height = 20;

      worksheet.addRows(excelData);

      worksheet.getRow(excelData.length + 2).font = { bold: true, size: 12 };

      const buffer = await workbook.xlsx.writeBuffer();
      saveAs(
        new Blob([buffer], { type: "application/octet-stream" }),
        `ParkingTransactions${
          props.dateFrom || props.dateTo
            ? `(${props.dateFrom?.format(
                "YYYY.MM.DD"
              )} - ${props.dateTo?.format("YYYY.MM.DD")})`
            : ""
        }.xlsx`
      );
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Button
      className="btn-default hvr-float-shadow h-10 w-40 ml-3"
      onClick={exportExcel}
    >
      {t("EXPORT XLS")}
    </Button>
  );
};

export default CostExportExcel;
