import React from "react";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  DatePicker,
  Form,
  Popconfirm,
  Table,
  Typography,
  Select,
} from "antd";
import { AppDispatch, RootState } from "../../store";
import { DownloadOutlined } from "@ant-design/icons";
import moment, { Moment } from "moment";
import axios from "axios";
import { useTranslation } from "react-i18next";
import SummariesExportExcel from "./SentSummariesExportExcel";
import { formInitialValues } from "../../@types/cost";

import EditableRow from "./EditableRow";
import EditableCell from "./EditableCell";
import EditableCellAll from "./EditableCellAll";
import { useParams } from "react-router-dom";
import { loadApartments } from "../../store/apartmentsSlice";

type EditableTableProps = Parameters<typeof Table>[0];
type ColumnTypes = Exclude<EditableTableProps["columns"], undefined>;

const SentSummaries: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const apartments = useSelector(
    (state: RootState) => state.apartments.apartments
  );
  const [filterApartments, setFilterApartments] = useState<any>([]);
  const [filterUsers, setFilterUsers] = useState<any>([]);
  const [filterBusinessSegment, setFilterBusinessSegment] = useState<string[]>(
    []
  );
  const [listSummaries, setListSummaries] = useState<any[]>([]);
  const [dateFrom, setDateFrom] = useState<Moment | null>(
    moment().startOf("year")
  );
  const [dateTo, setDateTo] = useState<Moment | null>(moment().endOf("year"));
  const [selectedACsRowKeys, setSelectedACsRowKeys] = useState<Array<any>>([]);
  const params = useParams();

  const user = useSelector((state: RootState) => state.auth.user);
  const users = useSelector((state: RootState) => state.users);

  const verifyUser = user?.Role === "admin" || user?.Role === "super-admin";
  const [t] = useTranslation("common");

  const [businessSegment, setBusinessSegment] = useState([]);

  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState("");
  const isEditing = (record: any) => record.ID === editingKey;

  const test = async () => {
    const values = await form.getFieldsValue();
    const { OwnerChargeSplit, CostValue } = values;
    const newCostValue = Number(OwnerChargeSplit) * Number(CostValue);
    form.setFieldsValue({ ...values, OwnerCostValue: newCostValue.toFixed(2) });
  };

  const components = {
    body: {
      row: editingKey ? undefined : EditableRow,
      cell: editingKey ? EditableCellAll : EditableCell,
    },
  };

  const edit = (record: any) => {
    form.setFieldsValue({ ...formInitialValues, ...record });
    setEditingKey(record.ID);
  };

  const handleAdd = () => {
    const arrayId = listSummaries.map(({ ID }) => Number(ID)) || [];
    const newId = Math.max(...arrayId) + 1;
    const newCost = { ...formInitialValues, ID: newId };
    setListSummaries([...listSummaries, newCost]);
    edit(newCost);
  };

  const fetchListSentSummaries = async () => {
    try {
      const res = await axios
        .get("/sentsummaries/list", {
          params: {
            apartments: filterApartments,
            users: filterUsers,
            businessSegment: filterBusinessSegment,
            from: dateFrom ? dateFrom.format("YYYY-MM-DD") : "",
            to: dateTo ? dateTo.format("YYYY-MM-DD") : "",
            ...(params.ownerId ? { ownerId: params.ownerId } : {}),
          },
        })
        .then((res) => res.data);

      setListSummaries(res);
    } catch (err) {
      console.log(err);
    }
  };

  const deleteSummaries = async (ID: string | number) => {
    try {
      await axios["delete"](`/sentsummaries/${ID}`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      fetchListSentSummaries();
    } catch (err) {
      console.log(err);
    }
  };

  const save = async (key: string | number) => {
    try {
      const row = await form.validateFields();
      const verifyDate = {
        ...row,
      };
      await axios["post"](
        `/sentsummaries/create`,
        {
          ...verifyDate,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      ).then(() => {
        fetchListSentSummaries();
        setEditingKey("");
      });
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const cancel = () => {
    fetchListSentSummaries();
    setEditingKey("");
  };

  const columns: (ColumnTypes[number] & {
    editable?: boolean;
    dataIndex: string;
  })[] = [
    {
      title: t("sentsummaries.table.ID"),
      dataIndex: "ID",
      defaultSortOrder: "ascend",
      width: 50,
      sorter: (a: any, b: any) =>
        (a.ID as unknown as string) > (b.ID as unknown as string) ? 1 : -1,
    },
    {
      title: t("sentsummaries.table.SentON"),
      dataIndex: "SentON",
      width: 120,
      editable: verifyUser,
      sorter: (a: any, b: any) =>
        (a.SentON as string) > (b.SentON as string) ? 1 : -1,
    },
    {
      title: t("sentsummaries.table.SentBy"),
      dataIndex: "SentBy",
      editable: verifyUser,
      width: 150,
      sorter: (a: any, b: any) =>
        (a.SentBy as string) > (b.SentBy as string) ? 1 : -1,
      render: (SentBy: string) => {
        return <span className="whitespace-nowrap">{SentBy}</span>;
      },
    },
    {
      title: <div>{t("sentsummaries.table.RoomName")}</div>,
      dataIndex: "RoomName",
      editable: verifyUser,
      width: 200,
      sorter: (a: any, b: any) =>
        (a.RoomName as string) > (b.RoomName as string) ? 1 : -1,
      render: (RoomName: string) => {
        return <span className="whitespace-nowrap">{RoomName}</span>;
      },
    },
    {
      title: <div>{t("sentsummaries.table.ParkingName")}</div>,
      dataIndex: "ParkingName",
      editable: verifyUser,
      width: 200,
      sorter: (a: any, b: any) =>
        (a.ParkingName as string) > (b.ParkingName as string) ? 1 : -1,
      render: (ParkingName: string) => {
        return <span className="whitespace-nowrap">{ParkingName}</span>;
      },
    },
    {
      title: t("sentsummaries.table.DateFrom"),
      dataIndex: "DateFrom",
      editable: verifyUser,
      width: 120,
      sorter: (a: any, b: any) =>
        (a.DateFrom as number) > (b.DateFrom as number) ? 1 : -1,
      render: (DateFrom: string) => {
        return (
          <span className="whitespace-nowrap">
            {DateFrom ? moment(DateFrom).format("YYYY-MM-DD") : ""}
          </span>
        );
      },
    },
    {
      title: <div>{t("sentsummaries.table.DateTo")}</div>,
      dataIndex: "DateTo",
      editable: verifyUser,
      width: 120,
      sorter: (a: any, b: any) =>
        (a.DateTo as number) > (b.DateTo as number) ? 1 : -1,
      render: (DateTo: string) => {
        return (
          <span className="whitespace-nowrap">
            {DateTo ? moment(DateTo).format("YYYY-MM-DD") : ""}
          </span>
        );
      },
    },

    {
      title: t("sentsummaries.table.OwnerName"),
      dataIndex: "OwnerName",
      editable: true,
      width: 90,
      sorter: (a: any, b: any) =>
        (a.OwnerName as number) > (b.OwnerName as number) ? 1 : -1,
      render: (OwnerName: string) => {
        return <span className="whitespace-nowrap">{OwnerName}</span>;
      },
    },
    {
      title: t("sentsummaries.table.ApartmentTotal"),
      dataIndex: "ApartmentTotal",
      width: 150,
      editable: verifyUser,
      sorter: (a: any, b: any) =>
        (a.ApartmentTotal as string) > (b.ApartmentTotal as string) ? 1 : -1,
      render: (ApartmentTotal: string) => {
        return <span className="whitespace-nowrap">{ApartmentTotal}</span>;
      },
    },
    {
      title: t("sentsummaries.table.CostTotal"),
      dataIndex: "CostTotal",
      width: 200,
      editable: verifyUser,
      sorter: (a: any, b: any) =>
        (a.CostTotal as string) > (b.CostTotal as string) ? 1 : -1,
      render: (CostTotal: string) => {
        return <span className="whitespace-nowrap">{CostTotal}</span>;
      },
    },
    {
      title: t("sentsummaries.table.parkingTotal"),
      dataIndex: "parkingTotal",
      width: 200,
      editable: verifyUser,
      sorter: (a: any, b: any) =>
        (a.parkingTotal as string) > (b.parkingTotal as string) ? 1 : -1,
      render: (parkingTotal: string) => {
        return <span className="whitespace-nowrap">{parkingTotal}</span>;
      },
    },
    {
      title: t("sentsummaries.table.FinalTotal"),
      dataIndex: "FinalTotal",
      width: 200,
      editable: verifyUser,
      sorter: (a: any, b: any) =>
        (a.FinalTotal as string) > (b.FinalTotal as string) ? 1 : -1,
      render: (FinalTotal: string) => {
        return <span className="whitespace-nowrap">{FinalTotal}</span>;
      },
    },
    {
      title: t("sentsummaries.table.BusinessSegment"),
      dataIndex: "BusinessSegment",
      width: 200,
      editable: verifyUser,
      sorter: (a: any, b: any) =>
        (a.BusinessSegment as string) > (b.BusinessSegment as string) ? 1 : -1,
      render: (BusinessSegment: string) => {
        return <span className="whitespace-nowrap">{BusinessSegment}</span>;
      },
    },
    {
      title: t("sentsummaries.table.SourceAccount"),
      dataIndex: "SourceAccount",
      width: 200,
      editable: verifyUser,
      sorter: (a: any, b: any) =>
        (a.SourceAccount as string) > (b.SourceAccount as string) ? 1 : -1,
      render: (SourceAccount: string) => {
        return <span className="whitespace-nowrap">{SourceAccount}</span>;
      },
    },
    {
      title: t("sentsummaries.table.DestinationAccount"),
      dataIndex: "DestinationAccount",
      width: 200,
      editable: verifyUser,
      sorter: (a: any, b: any) =>
        (a.DestinationAccount as string) > (b.DestinationAccount as string)
          ? 1
          : -1,
      render: (DestinationAccount: string) => {
        return <span className="whitespace-nowrap">{DestinationAccount}</span>;
      },
    },
    {
      title: "operation",
      dataIndex: "operation",
      render: (_, record: any) => {
        const editable = isEditing(record);

        return listSummaries.length >= 1 && verifyUser && editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.ID)}
              style={{
                marginRight: 8,
              }}
            >
              Save
            </Typography.Link>
            <Popconfirm
              title="Sure to cancel?"
              onConfirm={cancel}
              okType="danger"
            >
              <button>Cancel</button>
            </Popconfirm>
          </span>
        ) : listSummaries.length >= 1 && verifyUser ? (
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => deleteSummaries(record.ID)}
            okType="danger"
          >
            <Button key="delete" className="btn-danger">
              {t("DELETE")}
            </Button>
          </Popconfirm>
        ) : null;
      },
    },
  ];

  useEffect(() => {
    dispatch(loadApartments({ search: "", ownerId: "" }));
    fetchListSentSummaries();
    axios.get(`/sentsummaries/bs/`).then((res) => {
      setBusinessSegment(res.data);
    });
  }, []);

  const onDateChange = (dates: any) => {
    setDateFrom(dates ? dates[0] : null);
    setDateTo(dates ? dates[1] : null);
  };

  const disabledDate = (current: any) => {
    return current && current < moment("2021-12-31").endOf("day");
  };

  const showColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record: any) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        test: () => test(),
        handleSave: () => fetchListSentSummaries(),
      }),
    };
  });

  function formatDateToYYYYMMDD(date: Date) {
    const year = date.getFullYear().toString();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");

    return `${year}${month}${day}`;
  }
  function formatDateToDDMMYYYY(date: string) {
    const dateToFormat = new Date(date);

    const day = String(dateToFormat.getDate()).padStart(2, "0");
    const month = String(dateToFormat.getMonth() + 1).padStart(2, "0");
    const year = dateToFormat.getFullYear();

    return `${day}-${month}-${year}`;
  }

  function exportCSV() {
    if (selectedACsRowKeys.length > 0) {
      const selectedLines = listSummaries.filter((item) => {
        return selectedACsRowKeys.includes(item.ID);
      });

      let csvContent = "";

      const currentDate = new Date();
      const formattedDate = formatDateToYYYYMMDD(currentDate);

      selectedLines.forEach((item) => {
        const line = `110,${formattedDate},${Math.round(
          item.FinalTotal * 100
        )},${item.SourceAccount.substr(2, 8)},0,"${item.SourceAccount}","${
          item.DestinationAccount
        }","${item.BusinessSegment}","${
          item.OwnerName
        }",0,${item.DestinationAccount.substr(2, 8)},"${item.RoomName}+${
          item.ParkingName
        } za okres: ${formatDateToDDMMYYYY(
          item.DateFrom
        )} - ${formatDateToDDMMYYYY(item.DateTo)}","","","51",${item.ID}`;
        csvContent += line + "\n";
      });

      const blob = new Blob([csvContent], { type: "text/csv" });

      // Create a URL for the Blob
      const url = URL.createObjectURL(blob);

      // Create a link element for downloading
      const link = document.createElement("a");
      link.href = url;

      const fileName: string = `PLATNOSCI_IMPORT_${moment().format(
        "YYYYMMDDHHmmss"
      )}_BalticHomeMiedzyzdroje.csv`;

      link.download = fileName; // Set the desired filename

      // Append the link to the DOM and click it to trigger the download
      document.body.appendChild(link);
      link.click();

      // Clean up the URL and link
      URL.revokeObjectURL(url);
      document.body.removeChild(link);
    } else {
      alert("Please select rows!");
    }
  }

  return (
    <div className="container-xl mx-auto px-3 h-full pt-7 flex flex-col">
      <div className="mt-8 border-b mb-2 border-gray-400 lg:flex justify-between">
        <div className="flex items-center mb-2">
          <span className="font-bold mr-4">{t("transactions.Period")}:</span>

          <DatePicker.RangePicker
            ranges={{
              "This Month": [
                moment().startOf("month"),
                moment().endOf("month"),
              ],
            }}
            value={[dateFrom, dateTo]}
            onChange={onDateChange}
            disabledDate={verifyUser ? () => {} : disabledDate}
          />
          <Select
            className="w-40 ml-3"
            mode="multiple"
            allowClear
            placeholder="All"
            onChange={(value) => setFilterApartments(value)}
            value={filterApartments}
          >
            <Select.Option value="none">None</Select.Option>
            {apartments.map((apartment: any) => (
              <Select.Option
                key={apartment.RoomName}
                value={apartment.RoomName}
              >
                {apartment.RoomName}
              </Select.Option>
            ))}
          </Select>
          <Select
            className="w-40 ml-3"
            mode="multiple"
            allowClear
            placeholder="All"
            onChange={(value) => setFilterUsers(value)}
            value={filterUsers}
          >
            <Select.Option value="none">None</Select.Option>
            {users.owners.map((user: any) => (
              <Select.Option
                key={user.OwnerID}
                value={user.FirstName + " " + user.LastName}
              >
                {user.FirstName + " " + user.LastName}
              </Select.Option>
            ))}
          </Select>
          <Select
            className="w-40 ml-3"
            mode="multiple"
            allowClear
            placeholder="All"
            onChange={(value) => setFilterBusinessSegment(value)}
            value={filterBusinessSegment}
          >
            <Select.Option value="none">None</Select.Option>
            {businessSegment.map(
              (item: {
                RowID: number;
                BusinessSegment: string;
                AccountNumber: string;
              }) => (
                <Select.Option key={item.RowID} value={item.BusinessSegment}>
                  {item.BusinessSegment}
                </Select.Option>
              )
            )}
          </Select>
          <Button
            className="btn-default h-8 ml-2"
            onClick={() => fetchListSentSummaries()}
          >
            {t("Submit")}
          </Button>
        </div>
        <Button
          className="btn-default hvr-float-shadow h-10 w-40 ml-3"
          icon={<DownloadOutlined className="align-middle" />}
          onClick={() => {
            exportCSV();
          }}
          style={{ margin: "10px 0" }}
        >
          {t("EXPORT CSV")}
        </Button>
      </div>

      <div className="max-w-full overflow-auto">
        <Form form={form} component={false}>
          <Table
            components={components}
            rowKey="ID"
            columns={showColumns as ColumnTypes}
            dataSource={listSummaries}
            rowClassName={"hover:bg-white hover:bg-opacity-10"}
            className="border flex-grow"
            pagination={{
              hideOnSinglePage: true,
              defaultPageSize: 100,
            }}
            rowSelection={{
              selectedRowKeys: selectedACsRowKeys,
              onChange: (values) => {
                setSelectedACsRowKeys(values);
              },
            }}
          />
        </Form>
      </div>
      <div
        className="flex"
        style={{ width: "100%", justifyContent: "flex-end", marginTop: "10px" }}
      >
        {verifyUser && (
          <Button
            disabled={editingKey !== ""}
            onClick={handleAdd}
            type="primary"
            className="btn-yellow hvr-float-shadow h-10 w-40 ml-3"
            style={{ marginBottom: 16 }}
          >
            Add a row
          </Button>
        )}
        <SummariesExportExcel
          rows={listSummaries}
          dateFrom={dateFrom}
          dateTo={dateTo}
        />
      </div>
    </div>
  );
};

export default SentSummaries;
