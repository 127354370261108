import { Button } from "antd";
import ExcelJS from "exceljs";
import saveAs from "file-saver";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { ParkingTransaction } from "../../../../@types/parkingtransaction";
import {
  ApartmentTransaction,
  ApartmentOtherItems,
} from "../../../../@types/apartmenttransaction";
import { useTranslation } from "react-i18next";
import {
  getColumnsForWorksheet,
  getColumnsForInsert,
  addTotalString,
  parseToFloat,
} from "../../../../services/exportToExcel";
import type { TaddTotalString } from "../../../../services/exportToExcel";
import { excelLogo } from "../../../../constants/excelLogo";
import type { Workbook, Worksheet } from "exceljs";

import moment, { Moment } from "moment";

type CProps = {
  apartmentCalculations: Array<ApartmentTransaction>;
  apartmentOtherItems: Array<ApartmentOtherItems>;
  parkingCalculations: Array<ParkingTransaction>;
  dateFrom: Moment | null;
  dateTo: Moment | null;
};

const ReportTransactionsFullExcel: React.FC<CProps> = (props) => {
  const apartmentCalculations = props.apartmentCalculations;
  const apartmentOtherItems = props.apartmentOtherItems;
  const parkingCalculations = props.parkingCalculations;
  const [t] = useTranslation("common");

  const apartmentCalculationsExcelData = apartmentCalculations.map((row) => {
    return {
      RowID: row.RowID,
      RoomName: row.RoomName,
      DateFrom: row.DateFrom ? moment(row.DateFrom).format("YYYY-MM-DD") : "",
      DateTo: row.DateTo ? moment(row.DateTo).format("YYYY-MM-DD") : "",
      Nights: row.Nights,
      BookingSource: row.BookingSource,
      PriceMinusSourceCommision: parseToFloat(row.PriceMinusSourceCommision),
      PriceMinusTax: row.PriceMinusTax,
      PriceMinusBreakfast: row.PriceMinusBreakfast,
      PriceMinusBHCommision: row.PriceMinusBHCommision,
      PriceAccomodationPerNight: parseToFloat(row.PriceAccomodationPerNight),
      PriceMinusSourceCommisionPerNight: parseToFloat(
        row.PriceMinusSourceCommisionPerNight
      ),
      PriceMinusTaxPerNight: parseToFloat(row.PriceMinusTaxPerNight),
      BreakfastQty: parseToFloat(row.BreakfastQty),
      BreakFastUnitPrice: parseToFloat(row.BreakFastUnitPrice),
      PriceMinusBreakfastPerNight: row.PriceMinusBreakfastPerNight,
    };
  });

  const apartmentOtherItemsExcelData = apartmentOtherItems.map((row) => {
    return {
      ItemName: row.ItemName,
      Fee: parseToFloat(row.Fee),
      Count: parseToFloat(row.Count),
      FeeMinusBHCommission: parseToFloat(row.FeeMinusBHCommission),
      Total: parseToFloat(row.Total),
    };
  });

  const parkingCalculationsExcelData = parkingCalculations.map((row) => {
    return {
      DateFrom: row.DateFrom ? moment(row.DateFrom).format("YYYY-MM-DD") : "",
      DateTo: row.DateTo ? moment(row.DateTo).format("YYYY-MM-DD") : "",
      RowId: row.RowID,
      ParkingName: row.ParkingName,
      ParkingNights: row.ParkingNights,
      ParkingPriceMinusBHCommision: parseToFloat(
        row.ParkingPriceMinusBHCommision
      ),
      ParkingPriceMinusTax: parseToFloat(row.ParkingPriceMinusTax),
    };
  });

  const curUser = useSelector((state: RootState) => state.common.curUser);

  type TAddHeaderOptions = {
    imagePosition: { col: number; row: number };
  };
  const addHeader = (
    workbook: Workbook,
    worksheet: Worksheet,
    options: TAddHeaderOptions
  ) => {
    const image = workbook.addImage({
      base64: excelLogo,
      extension: "png",
    });
    const { imagePosition } = options;
    worksheet.addImage(image, {
      tl: imagePosition,
      ext: { width: 400, height: 180 },
    });

    worksheet.getCell("A1").value = "";
    worksheet.getCell("A2").value = "Baltic Home spółka z o.o";
    worksheet.getCell("A3").value = "Ul. Uzdrowiskowa 11/3, 72-600 Świnoujście";
    worksheet.getCell("A4").value = "NIP: 8551531507";

    if (curUser) {
      worksheet.getCell("A6").value = `${t("Owner")}: ${curUser.FirstName} ${
        curUser.LastName
      }`;
    }

    const dateFrom = props.dateFrom ? props.dateFrom.format("YYYY-MM-DD") : "";
    const dateTo = props.dateTo ? props.dateTo.format("YYYY-MM-DD") : "";
    if (dateFrom && dateTo) {
      worksheet.getCell("A8").value = `Period: ${dateFrom} - ${dateTo}`;
    }

    const cellsForMerge = [1, 2, 3, 4, 6, 8, 5, 7];
    cellsForMerge.forEach((item) => {
      const point1 = "A" + item;
      const point2 = "D" + item;
      worksheet.mergeCells(point1, point2);
    });
  };

  const addApartmentCalculationsWorksheet = (workbook: Workbook) => {
    const worksheet = workbook.addWorksheet("Apartment Transactions", {
      views: [{ state: "frozen", ySplit: 1 }],
    });

    const addHeaderOptions = {
      imagePosition: { col: 6, row: 0 },
    };
    addHeader(workbook, worksheet, addHeaderOptions);

    const tTable = "transactions.Apartment Transactions.table.";
    const columns = [
      { header: t(tTable + "Apartment name"), key: "RoomName", width: 11 },
      { header: t(tTable + "Date From"), key: "DateFrom", width: 11 },
      { header: t(tTable + "Date To"), key: "DateTo", width: 11 },
      { header: t(tTable + "Nights"), key: "Nights", width: 10 },
      {
        header: t(tTable + "Price Accomodation Per Night"),
        key: "PriceAccomodationPerNight",
        width: 10,
      },
      {
        header: t(tTable + "Price Minus Src Commission Per Night"),
        key: "PriceMinusSourceCommisionPerNight",
        width: 10,
      },
      {
        header: t(tTable + "Price Minus Tax Per Night"),
        key: "PriceMinusTaxPerNight",
        width: 10,
      },
      { header: t(tTable + "BreakfastQty"), key: "BreakfastQty", width: 10 },
      {
        header: t(tTable + "BreakFastUnitPrice"),
        key: "BreakFastUnitPrice",
        width: 10,
      },
      {
        header: t(tTable + "PriceMinusBreakfastPerNight"),
        key: "PriceMinusBreakfastPerNight",
        width: 10,
      },
      {
        header: t(tTable + "Price Minus Breakfast"),
        key: "PriceMinusBreakfast",
        width: 10,
      },
      {
        header: t(tTable + "Price Minus BH Commission"),
        key: "PriceMinusBHCommision",
        width: 10,
      },
      { header: t(tTable + "Booking Src"), key: "BookingSource", width: 19 },
    ];
    const columnsForWorksheet = getColumnsForWorksheet(columns);
    const columnsForInsert = getColumnsForInsert(columns);
    worksheet.columns = columnsForWorksheet;

    const insertAllRowsPosition = 15;
    const insertColumnsPosition = insertAllRowsPosition - 1;

    const headerPosition = insertColumnsPosition - 1;
    const startHeaderPosition = "A" + headerPosition;
    const endHeaderPosition = "C" + headerPosition;

    const textColor = "314768";

    worksheet.mergeCells(startHeaderPosition, endHeaderPosition);
    const headerCell = worksheet.getCell(startHeaderPosition);
    headerCell.value = "Apartment Transactions";
    headerCell.font = { bold: true, size: 11.5, color: { argb: textColor } };
    headerCell.alignment = {
      vertical: "middle",
      horizontal: "left",
    };
    worksheet.getRow(headerPosition).height = 25;

    worksheet.insertRows(insertColumnsPosition, columnsForInsert);
    const columnsRow = worksheet.getRow(insertColumnsPosition);

    worksheet.insertRows(insertAllRowsPosition, apartmentCalculationsExcelData);
    columnsRow.alignment = {
      vertical: "middle",
      horizontal: "center",
      wrapText: true,
    };
    columnsRow.height = 60;
    columnsRow.font = { bold: true, color: { argb: textColor } };

    const optionsForAddTotalString: TaddTotalString = {
      worksheet,
      insertAllRowsPosition,
      insertRowsLength: apartmentCalculationsExcelData.length,
      textColor,
      columnsForSum: ["D", "E", "F", "G", "H", "I", "J", "K", "L"],
    };
    addTotalString(optionsForAddTotalString);
  };

  const addApartmentOtherItemsWorksheet = (workbook: Workbook) => {
    const worksheet = workbook.addWorksheet("Other items", {
      views: [{ state: "frozen", ySplit: 1 }],
    });

    const addHeaderOptions = {
      imagePosition: { col: 4, row: 0 },
    };
    addHeader(workbook, worksheet, addHeaderOptions);

    const tTable = "transactions.Other items.";
    const columns = [
      { header: t(tTable + "Other items"), key: "ItemName", width: 15 },
      { header: t(tTable + "Fee"), key: "Fee", width: 10 },
      { header: t(tTable + "Count"), key: "Count", width: 10 },
      {
        header: t(tTable + "Fee Minus BH Commission"),
        key: "FeeMinusBHCommission",
        width: 27,
      },
      { header: t(tTable + "Total"), key: "Total", width: 15 },
    ];
    const columnsForWorksheet = getColumnsForWorksheet(columns);
    const columnsForInsert = getColumnsForInsert(columns);
    worksheet.columns = columnsForWorksheet;

    const insertAllRowsPosition = 15;
    const insertColumnsPosition = insertAllRowsPosition - 1;

    const headerPosition = insertColumnsPosition - 1;
    const startHeaderPosition = "A" + headerPosition;
    const endHeaderPosition = "C" + headerPosition;

    const textColor = "314768";

    worksheet.mergeCells(startHeaderPosition, endHeaderPosition);
    const headerCell = worksheet.getCell(startHeaderPosition);
    headerCell.value = "Other items";
    headerCell.font = { bold: true, size: 11.5, color: { argb: textColor } };
    headerCell.alignment = {
      vertical: "middle",
      horizontal: "left",
    };
    worksheet.getRow(headerPosition).height = 25;

    worksheet.insertRows(insertColumnsPosition, columnsForInsert);
    const columnsRow = worksheet.getRow(insertColumnsPosition);

    worksheet.insertRows(insertAllRowsPosition, apartmentOtherItemsExcelData);
    columnsRow.alignment = {
      vertical: "middle",
      horizontal: "center",
      wrapText: true,
    };
    columnsRow.height = 30;
    columnsRow.font = { bold: true, color: { argb: textColor } };

    const optionsForAddTotalString: TaddTotalString = {
      worksheet,
      insertAllRowsPosition,
      insertRowsLength: apartmentOtherItemsExcelData.length,
      textColor,
      columnsForSum: ["C", "D", "E"],
    };
    addTotalString(optionsForAddTotalString);
  };

  const addParkingCalculationsWorksheet = (workbook: Workbook) => {
    const worksheet = workbook.addWorksheet("Parking Transactions", {
      views: [{ state: "frozen", ySplit: 1 }],
    });

    const addHeaderOptions = {
      imagePosition: { col: 5, row: 0 },
    };
    addHeader(workbook, worksheet, addHeaderOptions);

    const tTable = "transactions.Parking Transactions.table.";
    const columns = [
      { header: t(tTable + "Parking name"), key: "ParkingName", width: 15 },
      { header: t(tTable + "Date From"), key: "DateFrom", width: 10 },
      { header: t(tTable + "Date To"), key: "DateTo", width: 10 },
      { header: t(tTable + "Nights"), key: "ParkingNights", width: 10 },
      {
        header: t(tTable + "Price Minus Tax"),
        key: "ParkingPriceMinusTax",
        width: 16,
      },
      {
        header: t(tTable + "Price Minus BH Commission"),
        key: "ParkingPriceMinusBHCommision",
        width: 15,
      },
    ];
    const columnsForWorksheet = getColumnsForWorksheet(columns);
    const columnsForInsert = getColumnsForInsert(columns);
    worksheet.columns = columnsForWorksheet;

    const insertAllRowsPosition = 15;
    const insertColumnsPosition = insertAllRowsPosition - 1;

    const headerPosition = insertColumnsPosition - 1;
    const startHeaderPosition = "A" + headerPosition;
    const endHeaderPosition = "C" + headerPosition;

    const textColor = "314768";

    worksheet.mergeCells(startHeaderPosition, endHeaderPosition);
    const headerCell = worksheet.getCell(startHeaderPosition);
    headerCell.value = "Parking Transactions";
    headerCell.font = { bold: true, size: 11.5, color: { argb: textColor } };
    headerCell.alignment = {
      vertical: "middle",
      horizontal: "left",
    };
    worksheet.getRow(headerPosition).height = 25;

    worksheet.insertRows(insertColumnsPosition, columnsForInsert);
    const columnsRow = worksheet.getRow(insertColumnsPosition);

    worksheet.insertRows(insertAllRowsPosition, parkingCalculationsExcelData);
    columnsRow.alignment = {
      vertical: "middle",
      horizontal: "center",
      wrapText: true,
    };
    columnsRow.height = 30;
    columnsRow.font = { bold: true, color: { argb: textColor } };

    const optionsForAddTotalString: TaddTotalString = {
      worksheet,
      insertAllRowsPosition,
      insertRowsLength: parkingCalculationsExcelData.length,
      textColor,
      columnsForSum: ["D", "E", "F"],
    };
    addTotalString(optionsForAddTotalString);
  };

  const exportExcel = async () => {
    try {
      const workbook = new ExcelJS.Workbook();
      addApartmentCalculationsWorksheet(workbook);
      addApartmentOtherItemsWorksheet(workbook);
      addParkingCalculationsWorksheet(workbook);

      const buffer = await workbook.xlsx.writeBuffer();
      saveAs(
        new Blob([buffer], { type: "application/octet-stream" }),
        `ParkingTransactions${
          props.dateFrom || props.dateTo
            ? `(${props.dateFrom?.format(
                "YYYY.MM.DD"
              )} - ${props.dateTo?.format("YYYY.MM.DD")})`
            : ""
        }.xlsx`
      );
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Button
      className="btn-default hvr-float-shadow h-10 w-40 ml-3"
      onClick={exportExcel}
    >
      {t("EXPORT XLS")}
    </Button>
  );
};

export default ReportTransactionsFullExcel;
