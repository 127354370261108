import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import moment from "moment";
import axios from "axios";
import { Select, Tooltip } from "antd";
import { RootState } from "../../store";
const { Option } = Select;

export default function ParkingCalendarFrom() {
  const curUser = useSelector((state: RootState) => state.common.curUser);
  const [options, setOptions] = useState<any>([]);
  const [dates, setDates] = useState<any>([]);
  const [, setRoom] = useState<string>("");

  const [t] = useTranslation("common");

  function isReserved(date: Date, dateObj: any = dates) {
    var res = false;

    dateObj.forEach((elm: any) => {
      if (
        date.getTime() >= elm[0].getTime() &&
        date.getTime() <= elm[1].getTime()
      ) {
        res = true;
      }
    });
    return res;
  }

  function getTooltipContent(date: Date, dateObj: any = dates) {
    let content = "";
    dateObj.forEach((elm: any) => {
      if (
        date.getTime() >= elm[0].getTime() &&
        date.getTime() <= elm[1].getTime()
      ) {
        const fromDate = moment(elm[0]).add(1, "days").format("DD.MM.YYYY");
        const toDate = moment(elm[1]).add(1, "days").format("DD.MM.YYYY");
        content = `Od ${fromDate} - Do ${toDate}`;
      }
    });
    return content;
  }

  function tileDisabled({ date, view }: any): boolean {
    if (view === "month") {
      return isReserved(date, dates);
    }
    return false;
  }

  const fetchParkings = async ({
    search,
    ownerId,
  }: {
    search: string;
    ownerId: any;
  }) => {
    return await axios
      .get(`/parkings/list`, {
        params: { search: search || "", ownerId: ownerId || "" },
      })
      .then((res) => res.data);
  };

  const fetchParkingTransactions = async (parking: string | boolean) => {
    const res = await axios
      .get("/parking-transactions/list", {
        params: {
          from: "",
          to: "",
          parking: parking || "",
        },
      })
      .then((res) => {
        return res.data;
      });

    return res;
  };

  const loadDates = (e: any) => {
    fetchParkingTransactions(e).then((res: []) => {
      var arr: any = [];

      res.forEach(
        (elm: {
          ParkingName: string;
          DateFrom: string;
          DateTo: string;
        }) => {
          const to = new Date(elm.DateTo);
          to.setDate(to.getDate() - 1);
          const from = new Date(elm.DateFrom);
          from.setDate(from.getDate() - 1);
          arr.push([from, to]);
        }
      );
      setDates(arr);
      setRoom(e);
    });
  };

  useEffect(() => {
    fetchParkings({ search: "", ownerId: Number(curUser?.OwnerID) }).then(
      (res) => {
        const RoomSet = new Set();

        res.forEach((elm: { ParkingName: string }) => {
          RoomSet.add(elm.ParkingName.trim());
        });

        setOptions(Array.from(RoomSet.values()));
      }
    );
  }, []);

  function renderTileContent({ date, view }: { date: Date; view: string }) {
    if (view !== "month") {
      return null;
    }

    const content = <div>{date.getDate()}</div>;

    return isReserved(date, dates) ? (
      <Tooltip title={getTooltipContent(date, dates)}>{content}</Tooltip>
    ) : (
      content
    );
  }

  return (
    <div>
      <div className="mb-2">
        <label htmlFor="Type" className="font-semibold">
          {t("Parking")}:
        </label>
      </div>
      <Select
        defaultValue="---"
        style={{
          width: 350,
          marginBottom: "20px",
        }}
        onChange={(e) => {
          loadDates(e);
        }}
      >
        {options.map((elm: string) => {
          return <Option key={elm}>{elm}</Option>;
        })}
        ;
      </Select>
      <div
        style={{
          marginBottom: "20px",
        }}
      >
        <Calendar
          value={new Date()}
          tileDisabled={tileDisabled}
          tileContent={renderTileContent}
        />
      </div>
    </div>
  );
}
