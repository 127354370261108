import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import moment from "moment";
import axios from "axios";
import { Select, Tooltip } from "antd";
import { RootState } from "../../store";
const { Option } = Select;

export default function ApartmentCalendarFrom() {
  const curUser = useSelector((state: RootState) => state.common.curUser);
  const [options, setOptions] = useState<any>([]);
  const [dates, setDates] = useState<any>([]);
  const [, setRoom] = useState<string>("");

  const [t] = useTranslation("common");

  function isReserved(date: Date, dateObj: any = dates) {
    var res = false;

    dateObj.forEach((elm: any) => {
      if (
        date.getTime() >= elm[0].getTime() &&
        date.getTime() <= elm[1].getTime()
      ) {
        res = true;
      }
    });
    return res;
  }

  function checkBookSource(
    date: Date,
    dateObj: any = dates,
    bookingSource: string[]
  ) {
    var res = "";

    dateObj.forEach((elm: any) => {
      if (
        date.getTime() >= elm[0].getTime() &&
        date.getTime() <= elm[1].getTime()
      ) {
        if (bookingSource.includes(elm[2])) {
          res = "yellow";
        }
      }
    });
    return res;
  }

  function getTooltipContent(date: Date, dateObj: any = dates) {
    let content = "";
    dateObj.forEach((elm: any) => {
      if (
        date.getTime() >= elm[0].getTime() &&
        date.getTime() <= elm[1].getTime()
      ) {
        const fromDate = moment(elm[0]).add(1, "days").format("DD.MM.YYYY");
        const toDate = moment(elm[1]).add(1, "days").format("DD.MM.YYYY");
        content = `Od ${fromDate} - Do ${toDate}`;
      }
    });
    return content;
  }

  function tileClassName({ date, view }: any) {
    if (view === "month") {
      return checkBookSource(date, dates, ["wlasciciel", "Właściciel"]);
    }
    return "";
  }

  function tileDisabled({ date, view }: any): boolean {
    if (view === "month") {
      return isReserved(date, dates);
    }
    return false;
  }

  const fetchApartments = async ({
    search,
    ownerId,
  }: {
    search: string;
    ownerId: any;
  }) => {
    return await axios
      .get(`/apartments/list`, {
        params: { search: search || "", ownerId: ownerId || "" },
      })
      .then((res) => res.data);
  };

  const fetchApartmentTransactions = async (apartment: string | boolean) => {
    const res = await axios
      .get("/apartment-transactions/list", {
        params: {
          from: "",
          to: "",
          apartment: apartment || "",
        },
      })
      .then((res) => {
        return res.data;
      });

    return res;
  };

  const loadDates = (e: any) => {
    fetchApartmentTransactions(e).then((res: []) => {
      var arr: any = [];

      res.forEach(
        (elm: {
          RoomName: string;
          DateFrom: string;
          DateTo: string;
          BookingSource: string;
        }) => {
          const to = new Date(elm.DateTo);
          to.setDate(to.getDate() - 1);
          const from = new Date(elm.DateFrom);
          from.setDate(from.getDate() - 1);
          const bookingSource = elm.BookingSource;
          arr.push([from, to, bookingSource]);
        }
      );
      setDates(arr);
      setRoom(e);
    });
  };

  useEffect(() => {
    fetchApartments({ search: "", ownerId: Number(curUser?.OwnerID) }).then(
      (res) => {
        const RoomSet = new Set();

        res.list.forEach((elm: { RoomName: string }) => {
          RoomSet.add(elm.RoomName.trim());
        });

        setOptions(Array.from(RoomSet.values()));
      }
    );
  }, []);

  function renderTileContent({ date, view }: { date: Date; view: string }) {
    if (view !== "month") {
      return null;
    }

    const content = <div>{date.getDate()}</div>;

    return isReserved(date, dates) ? (
      <Tooltip title={getTooltipContent(date, dates)}>{content}</Tooltip>
    ) : (
      content
    );
  }

  return (
    <div>
      <div className="mb-2">
        <label htmlFor="Type" className="font-semibold">
          {t("Apartment")}:
        </label>
      </div>
      <Select
        defaultValue="---"
        style={{
          width: 350,
          marginBottom: "20px",
        }}
        onChange={(e) => {
          loadDates(e);
        }}
      >
        {options.map((elm: string) => {
          return <Option key={elm}>{elm}</Option>;
        })}
        ;
      </Select>
      <div
        style={{
          marginBottom: "20px",
        }}
      >
        <Calendar
          value={new Date()}
          tileDisabled={tileDisabled}
          tileClassName={tileClassName}
          tileContent={renderTileContent}
        />
      </div>
    </div>
  );
}
